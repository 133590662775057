/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ProfileRepresentation } from '../models/profile-representation';

@Injectable({
	providedIn: 'root',
})
export class UserControllerService extends BaseService {
	constructor(
		config: ApiConfiguration,
		http: HttpClient
	) {
		super(config, http);
	}

  /**
   * Path part for operation update2
   */
  static readonly Update2Path = '/api/admin/users/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `update2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update2$Response(params: {
    id: number;
    body: ProfileRepresentation
  }): Observable<StrictHttpResponse<ProfileRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.Update2Path, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProfileRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `update2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update2(params: {
    id: number;
    body: ProfileRepresentation
  }): Observable<ProfileRepresentation> {

    return this.update2$Response(params).pipe(
      map((r: StrictHttpResponse<ProfileRepresentation>) => r.body as ProfileRepresentation)
    );
  }

  /**
   * Path part for operation delete
   */
  static readonly DeletePath = '/api/admin/users/{id}/delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `delete()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.DeletePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `delete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete(params: {
    id: string;
  }): Observable<void> {

    return this.delete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deactivate
   */
  static readonly DeactivatePath = '/api/admin/users/{id}/deactivate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deactivate()` instead.
   *
   * This method doesn't expect any request body.
   */
  deactivate$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<ProfileRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.DeactivatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProfileRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deactivate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deactivate(params: {
    id: string;
  }): Observable<ProfileRepresentation> {

    return this.deactivate$Response(params).pipe(
      map((r: StrictHttpResponse<ProfileRepresentation>) => r.body as ProfileRepresentation)
    );
  }

  /**
   * Path part for operation activate
   */
  static readonly ActivatePath = '/api/admin/users/{id}/activate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activate()` instead.
   *
   * This method doesn't expect any request body.
   */
  activate$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<ProfileRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.ActivatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProfileRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  activate(params: {
    id: string;
  }): Observable<ProfileRepresentation> {

    return this.activate$Response(params).pipe(
      map((r: StrictHttpResponse<ProfileRepresentation>) => r.body as ProfileRepresentation)
    );
  }

  /**
   * Path part for operation fixUserNames
   */
  static readonly FixUserNamesPath = '/api/admin/userNames/fix';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fixUserNames()` instead.
   *
   * This method doesn't expect any request body.
   */
  fixUserNames$Response(params?: {
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.FixUserNamesPath, 'put');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fixUserNames$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fixUserNames(params?: {
  }): Observable<void> {

    return this.fixUserNames$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation register
   */
  static readonly RegisterPath = '/api/register/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `register()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  register$Response(params: {
    body: ProfileRepresentation
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.RegisterPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `register$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  register(params: {
    body: ProfileRepresentation
  }): Observable<void> {

    return this.register$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getAll
   */
  static readonly GetAllPath = '/api/admin/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll$Response(params?: {
  }): Observable<StrictHttpResponse<Array<ProfileRepresentation>>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.GetAllPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProfileRepresentation>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll(params?: {
  }): Observable<Array<ProfileRepresentation>> {

    return this.getAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProfileRepresentation>>) => r.body as Array<ProfileRepresentation>)
    );
  }

  /**
   * Path part for operation create
   */
  static readonly CreatePath = '/api/admin/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params: {
    body: ProfileRepresentation
  }): Observable<StrictHttpResponse<ProfileRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.CreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProfileRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params: {
    body: ProfileRepresentation
  }): Observable<ProfileRepresentation> {

    return this.create$Response(params).pipe(
      map((r: StrictHttpResponse<ProfileRepresentation>) => r.body as ProfileRepresentation)
    );
  }

  /**
   * Path part for operation getUser
   */
  static readonly GetUserPath = '/api/user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser$Response(params?: {
  }): Observable<StrictHttpResponse<ProfileRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.GetUserPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProfileRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser(params?: {
  }): Observable<ProfileRepresentation> {

    return this.getUser$Response(params).pipe(
      map((r: StrictHttpResponse<ProfileRepresentation>) => r.body as ProfileRepresentation)
    );
  }

  /**
   * Path part for operation activateUser
   */
  static readonly ActivateUserPath = '/api/register/users/activate/{token}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activateUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  activateUser$Response(params: {
    token: string;
  }): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.ActivateUserPath, 'get');
    if (params) {
      rb.path('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activateUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  activateUser(params: {
    token: string;
  }): Observable<{
}> {

    return this.activateUser$Response(params).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation getAllForRole
   */
  static readonly GetAllForRolePath = '/api/admin/users/{role}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllForRole()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllForRole$Response(params: {
    role: string;
  }): Observable<StrictHttpResponse<Array<ProfileRepresentation>>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.GetAllForRolePath, 'get');
    if (params) {
      rb.path('role', params.role, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProfileRepresentation>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllForRole$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllForRole(params: {
    role: string;
  }): Observable<Array<ProfileRepresentation>> {

    return this.getAllForRole$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProfileRepresentation>>) => r.body as Array<ProfileRepresentation>)
    );
  }

  /**
   * Path part for operation getScientificCouncilMembers
   */
  static readonly GetScientificCouncilMembersPath = '/api/admin/users/scientific_council';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getScientificCouncilMembers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScientificCouncilMembers$Response(params?: {
  }): Observable<StrictHttpResponse<Array<ProfileRepresentation>>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.GetScientificCouncilMembersPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProfileRepresentation>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getScientificCouncilMembers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScientificCouncilMembers(params?: {
  }): Observable<Array<ProfileRepresentation>> {

    return this.getScientificCouncilMembers$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProfileRepresentation>>) => r.body as Array<ProfileRepresentation>)
    );
  }

  /**
   * Path part for operation get1
   */
  static readonly Get1Path = '/api/admin/user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `get1()` instead.
   *
   * This method doesn't expect any request body.
   */
  get1$Response(params: {
    username: string;
  }): Observable<StrictHttpResponse<ProfileRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.Get1Path, 'get');
    if (params) {
      rb.query('username', params.username, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProfileRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `get1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  get1(params: {
    username: string;
  }): Observable<ProfileRepresentation> {

    return this.get1$Response(params).pipe(
      map((r: StrictHttpResponse<ProfileRepresentation>) => r.body as ProfileRepresentation)
    );
  }

}
