/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { storeJwt, storeUser } from '../shared/constants';
import { ProfileRepresentation } from '../generated/models';

@Injectable({ providedIn: 'root' })
export class AuthService {
	private currentUserSubject: BehaviorSubject<ProfileRepresentation>;
	public currentUser: Observable<ProfileRepresentation>;
	public userPermissions: string[] = [];

	constructor() {
		this.currentUserSubject = new BehaviorSubject<ProfileRepresentation>(
			JSON.parse(localStorage.getItem(storeUser) as string),
		);
		this.currentUser = this.currentUserSubject.asObservable();
		this.setUserPermissions();
	}

	public get currentUserValue(): ProfileRepresentation {
		return this.currentUserSubject.value;
	}

	/**
	 * Store current user
	 */
	storeUser(user: ProfileRepresentation) {
		// store user details in local storage to keep user logged in between page refreshes
		localStorage.setItem(storeUser, JSON.stringify(user));

		this.currentUserSubject.next(user);
		this.setUserPermissions();
	}

	/**
	 * Logout the user. Remove from local storage
	 */
	logout() {
		localStorage.removeItem(storeUser);
		localStorage.removeItem(storeJwt);
		this.currentUserSubject.next(null);
		this.userPermissions = [];
	}

	/**
	 * Does the currentUser has a role
	 */
	hasRole(role: string) {
		return this.currentUserValue?.currentRole === role;
	}

	/**
	 * Get the currentUser role
	 */
	getRole(): string {
		return this.currentUserValue?.currentRole;
	}

	/**
	 * Sets active role for user locally, doesnt sync to backend
	 * @param role Name of selected role
	 */
	setCurrentRole(role: string): void {
		const updatedUser = {
			...this.currentUserValue,
			currentRole: role,
		};
		this.storeUser(updatedUser);
	}

	/**
	 * Assign the permissions for the current user role
	 */
	private setUserPermissions() {
		this.userPermissions = this.getUserPermissions(
			this.currentUserValue?.currentRole,
		);
	}

	/**
	 * Get the permissions for the current user role
	 */
	private getUserPermissions(role: string): string[] {
		const userPermissions = [];
		switch (role) {
			case 'palga':
				userPermissions.push(
					permissions.LABS,
					permissions.USERS,
					permissions.REQUESTS,
					permissions.LAB_REQUESTS,
					permissions.SAMPLES,
					permissions.ACCESS_LOGS,
					permissions.AGREEMENT_FORM,
					permissions.TRANSFERS,
				);
				break;
			case 'lab_user':
				userPermissions.push(
					permissions.OWN_LAB,
					permissions.LAB_REQUESTS,
					permissions.SAMPLES,
				);
				break;
			case 'hub_user':
				userPermissions.push(
					permissions.LAB_REQUESTS,
					permissions.SAMPLES,
					permissions.HUB_LABS,
				);
				break;
			case 'requester':
				userPermissions.push(
					permissions.REQUESTS,
					permissions.LAB_REQUESTS,
				);
				break;
			case 'scientific_council':
				userPermissions.push(permissions.REQUESTS);
				break;
		}

		return userPermissions;
	}

	/**
	 * Check if the user has one of these permissions
	 */
	doesUserHasPermission(permissions: string[]): boolean {
		return this.userPermissions.some((perm) => permissions.includes(perm));
	}
}

/**
 * Permissions for main pages and management subpages
 */
export const permissions = {
	REQUESTS: 'REQUESTS',
	LAB_REQUESTS: 'LAB_REQUESTS',
	SAMPLES: 'SAMPLES',
	USERS: 'USERS',
	LABS: 'LABS',
	ACCESS_LOGS: 'ACCESS_LOGS',
	AGREEMENT_FORM: 'AGREEMENT_FORM',
	OWN_LAB: 'OWN_LAB',
	HUB_LABS: 'HUB_LABS',
	TRANSFERS: 'TRANSFERS',
};
