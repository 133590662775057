/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
import { Component, Input, OnInit } from '@angular/core';
import { ContactData } from 'src/app/modules/generated/models';

@Component({
	selector: 'palga-contact-data-address',
	templateUrl: './contact-data-address.component.html',
	styleUrls: ['./contact-data-address.component.scss'],
})
export class ContactDataAddressComponent implements OnInit {
	@Input() contactData: ContactData;

	constructor() {}

	ngOnInit(): void {}
}
