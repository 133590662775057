/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
import { TranslateService } from '@ngx-translate/core';
import { nl_translations } from 'src/translations/nl';
import { en_translations } from 'src/translations/en';
import { LanguageService } from './modules/shared/services/language.service';

export function AppStartup(
	translateService: TranslateService,
	languageService: LanguageService,
) {
	return () =>
		new Promise<any>((resolve: any) => {
			translateService.setTranslation('nl', nl_translations);
			translateService.setTranslation('en', en_translations);

			translateService.use(languageService.getLanguage());

			resolve(null);
		});
}
