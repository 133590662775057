/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'palga-button',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
	// the color for this button
	@Input() color: string = 'primary';

	// the mouse over color of the button
	@Input() hoverColor: string = '';

	// the icon to show in the button
	@Input() icon: string | null = null;

	// the text to show on the button
	@Input() text: string = '';

	// whether the button can be clicked
	@Input() disabled: boolean = false;

	/**
	 * Class aplied to button on mouseover
	 */
	public hoverClass: string;

	constructor() {}

	ngOnInit(): void {}

	/**
	 * Sets the class set by hoverColor on mouseover
	 * @param mouseIn True on mouseover, undefined on mouseout
	 */
	setHoverColor(mouseIn?: boolean) {
		this.hoverClass = mouseIn ? `text-${this.hoverColor}` : '';
	}
}
