<!--
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
-->
<div
	class="d-flex justify-content-between text-center"
	*ngIf="dataLength !== 0"
>
	<ngb-pagination
		class="mx-auto pt-3"
		[(page)]="dataSource.currentPage"
		[pageSize]="dataSource.selectedPageSize"
		[collectionSize]="dataLength"
		[boundaryLinks]="true"
		[directionLinks]="true"
		[maxSize]="25"
		[rotate]="true"
	></ngb-pagination>

	<select
		(change)="dataSource.setPageSize($event)"
		[(value)]="dataSource.selectedPageSize"
		class="my-auto py-2"
	>
		<option
			*ngFor="let size of dataSource.pageSizeOptions"
			[value]="size"
			[selected]="size == dataSource.selectedPageSize"
		>
			{{ size }}
		</option>
	</select>
</div>
