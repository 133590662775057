<!--
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
-->
<button
	class="btn pe-auto btn-{{ color }} {{ hoverClass }}"
	(mouseover)="setHoverColor(true)"
	(mouseout)="setHoverColor()"
	[disabled]="disabled"
>
	<i
		*ngIf="icon"
		class="bi-{{ icon }}"
	></i>
	{{ text | translate }}
</button>
