/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CommentRepresentation } from '../models/comment-representation';

@Injectable({
	providedIn: 'root',
})
export class CommentControllerService extends BaseService {
	constructor(
		config: ApiConfiguration,
		http: HttpClient
	) {
		super(config, http);
	}

  /**
   * Path part for operation updateComment
   */
  static readonly UpdateCommentPath = '/api/requests/{id}/comments/{commentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateComment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateComment$Response(params: {
    id: string;
    commentId: number;
    body: CommentRepresentation
  }): Observable<StrictHttpResponse<CommentRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, CommentControllerService.UpdateCommentPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('commentId', params.commentId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateComment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateComment(params: {
    id: string;
    commentId: number;
    body: CommentRepresentation
  }): Observable<CommentRepresentation> {

    return this.updateComment$Response(params).pipe(
      map((r: StrictHttpResponse<CommentRepresentation>) => r.body as CommentRepresentation)
    );
  }

  /**
   * Path part for operation removeComment
   */
  static readonly RemoveCommentPath = '/api/requests/{id}/comments/{commentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeComment()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeComment$Response(params: {
    id: string;
    commentId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CommentControllerService.RemoveCommentPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('commentId', params.commentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeComment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeComment(params: {
    id: string;
    commentId: number;
  }): Observable<void> {

    return this.removeComment$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updateLabRequestComment
   */
  static readonly UpdateLabRequestCommentPath = '/api/labrequests/{id}/comments/{commentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateLabRequestComment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateLabRequestComment$Response(params: {
    id: number;
    commentId: number;
    body: CommentRepresentation
  }): Observable<StrictHttpResponse<CommentRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, CommentControllerService.UpdateLabRequestCommentPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('commentId', params.commentId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateLabRequestComment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateLabRequestComment(params: {
    id: number;
    commentId: number;
    body: CommentRepresentation
  }): Observable<CommentRepresentation> {

    return this.updateLabRequestComment$Response(params).pipe(
      map((r: StrictHttpResponse<CommentRepresentation>) => r.body as CommentRepresentation)
    );
  }

  /**
   * Path part for operation removeLabRequestComment
   */
  static readonly RemoveLabRequestCommentPath = '/api/labrequests/{id}/comments/{commentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeLabRequestComment()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeLabRequestComment$Response(params: {
    id: number;
    commentId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CommentControllerService.RemoveLabRequestCommentPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('commentId', params.commentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeLabRequestComment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeLabRequestComment(params: {
    id: number;
    commentId: number;
  }): Observable<void> {

    return this.removeLabRequestComment$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getComments
   */
  static readonly GetCommentsPath = '/api/requests/{id}/comments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getComments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getComments$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<Array<CommentRepresentation>>> {

    const rb = new RequestBuilder(this.rootUrl, CommentControllerService.GetCommentsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CommentRepresentation>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getComments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getComments(params: {
    id: string;
  }): Observable<Array<CommentRepresentation>> {

    return this.getComments$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CommentRepresentation>>) => r.body as Array<CommentRepresentation>)
    );
  }

  /**
   * Path part for operation addComment
   */
  static readonly AddCommentPath = '/api/requests/{id}/comments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addComment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addComment$Response(params: {
    id: string;
    body: CommentRepresentation
  }): Observable<StrictHttpResponse<CommentRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, CommentControllerService.AddCommentPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addComment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addComment(params: {
    id: string;
    body: CommentRepresentation
  }): Observable<CommentRepresentation> {

    return this.addComment$Response(params).pipe(
      map((r: StrictHttpResponse<CommentRepresentation>) => r.body as CommentRepresentation)
    );
  }

  /**
   * Path part for operation getApprovalComments
   */
  static readonly GetApprovalCommentsPath = '/api/requests/{id}/approvalComments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApprovalComments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApprovalComments$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<Array<CommentRepresentation>>> {

    const rb = new RequestBuilder(this.rootUrl, CommentControllerService.GetApprovalCommentsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CommentRepresentation>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getApprovalComments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApprovalComments(params: {
    id: string;
  }): Observable<Array<CommentRepresentation>> {

    return this.getApprovalComments$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CommentRepresentation>>) => r.body as Array<CommentRepresentation>)
    );
  }

  /**
   * Path part for operation addApprovalComment
   */
  static readonly AddApprovalCommentPath = '/api/requests/{id}/approvalComments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addApprovalComment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addApprovalComment$Response(params: {
    id: string;
    body: CommentRepresentation
  }): Observable<StrictHttpResponse<CommentRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, CommentControllerService.AddApprovalCommentPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addApprovalComment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addApprovalComment(params: {
    id: string;
    body: CommentRepresentation
  }): Observable<CommentRepresentation> {

    return this.addApprovalComment$Response(params).pipe(
      map((r: StrictHttpResponse<CommentRepresentation>) => r.body as CommentRepresentation)
    );
  }

  /**
   * Path part for operation addLabRequestComment
   */
  static readonly AddLabRequestCommentPath = '/api/labrequests/{id}/comments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addLabRequestComment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addLabRequestComment$Response(params: {
    id: number;
    body: CommentRepresentation
  }): Observable<StrictHttpResponse<CommentRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, CommentControllerService.AddLabRequestCommentPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addLabRequestComment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addLabRequestComment(params: {
    id: number;
    body: CommentRepresentation
  }): Observable<CommentRepresentation> {

    return this.addLabRequestComment$Response(params).pipe(
      map((r: StrictHttpResponse<CommentRepresentation>) => r.body as CommentRepresentation)
    );
  }

  /**
   * Path part for operation removeApprovalComment
   */
  static readonly RemoveApprovalCommentPath = '/api/requests/{id}/approvalComments/{commentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeApprovalComment()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeApprovalComment$Response(params: {
    id: string;
    commentId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CommentControllerService.RemoveApprovalCommentPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('commentId', params.commentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeApprovalComment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeApprovalComment(params: {
    id: string;
    commentId: number;
  }): Observable<void> {

    return this.removeApprovalComment$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
