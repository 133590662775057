/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
import {
	APP_INITIALIZER,
	CUSTOM_ELEMENTS_SCHEMA,
	NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApiModule } from './modules/generated/api.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SharedModule } from './modules/shared/shared.module';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AppStartup } from './app.startup';
import { LanguageService } from './modules/shared/services/language.service';
import { ApiConfiguration } from './modules/generated/api-configuration';
import { environment } from 'src/environments/environment';
import { QuillModule } from 'ngx-quill';
import { JwtInterceptor } from './modules/auth/jwt.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		TranslateModule.forRoot(),
		SharedModule,
		ApiModule,
		QuillModule.forRoot(),
		BrowserAnimationsModule,
	],
	providers: [
		// AppStartup can be used to do stuff BEFORE the app will continue to load
		{
			provide: APP_INITIALIZER,
			useFactory: AppStartup,
			deps: [TranslateService, LanguageService],
			multi: true,
		},
		// Add the JWT token to each API request
		{
			provide: HTTP_INTERCEPTORS,
			useClass: JwtInterceptor,
			multi: true,
		},
		// set the Swagger / API url for the generated module
		{
			provide: ApiConfiguration,
			useValue: { rootUrl: environment.apiUrl },
		},
	],
	bootstrap: [AppComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
