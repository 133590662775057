/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { AuthService } from 'src/app/modules/auth/auth.service';
import { VERSION } from 'src/environments/version';

@Component({
	selector: 'palga-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
	currentYear: number;
	version: string;
	commit: string;

	footerString: string;

	private palgaString = 'Stichting Palga';

	private dntpString = 'DNTP — Dutch National Tissuebank Portal';

	isDntpPage: boolean;

	constructor(
		private route: Router,
		private auth: AuthService,
	) {
		this.currentYear = new Date().getFullYear();
		this.version = VERSION.version;
		this.commit = VERSION.hash;
		this.route.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				map((event: any) => event.url.includes('/lab-request')),
			)
			.subscribe((isDntpPage: boolean) => {
				this.isDntpPage = isDntpPage;

				// also change theme for lab and hub users
				if (
					this.auth.hasRole('lab_user') ||
					this.auth.hasRole('hub_user')
				) {
					this.isDntpPage = true;
				}

				this.footerString = this.isDntpPage
					? this.dntpString
					: this.palgaString;
				if (!this.auth.currentUserValue) {
					this.footerString = `${this.palgaString} | ${this.dntpString}`;
				}
			});
	}

	ngOnInit(): void {}
}
