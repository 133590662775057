<!--
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
-->
<div class="modal-header">
	<button
		type="button"
		class="btn-close"
		aria-label="Close"
		(click)="activeModal.dismiss(false)"
	></button>
</div>
<div class="modal-body">
	<p [innerHTML]="content"></p>
</div>
<div class="modal-footer">
	<palga-button
		color="danger"
		text="Cancel"
		(click)="activeModal.close(false)"
	></palga-button>

	<palga-button
		color="primary"
		text="{{ 'Discard changes' | translate }}"
		(click)="activeModal.close(true)"
	></palga-button>
</div>
