/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';

@Component({
	selector: 'palga-sample-list',
	templateUrl: './sample-list.component.html',
	styleUrls: ['./sample-list.component.scss'],
})
export class SampleListComponent implements OnInit {
	@Input() samples: string[];

	@Input() disabled: boolean;

	@Input() placeholder: string = '';

	@Output() samplesAdded = new EventEmitter<string[]>();

	@Output() samplesRemoved = new EventEmitter<string[]>();

	@ViewChild('sampleInput', { static: false }) sampleInput: ElementRef;

	constructor() {}

	ngOnInit(): void {
		if (!this.samples) {
			this.samples = [];
		}
	}

	addSample(event: any): void {
		if (event?.target?.value) {
			this.addSampleText(event.target.value);
		}
	}

	addSampleText(text: string): void {
		this.samples.push(text);
		this.sampleInput.nativeElement.value = '';
		this.samplesAdded.emit(this.samples);
	}

	removeSample(index: number): void {
		this.samples.splice(index, 1);
		this.samplesRemoved.emit(this.samples);
	}

	countSamples() {
		let number = this.samples.length;
		if (this.samples.includes('BLANCOCOUPES')) {
			number -= 1;
		}
		return number;
	}

	/**
	 * Called on keyup from input
	 */
	handleComma(event: any) {
		// Get the value as entered by user
		const targetElement = event.target as HTMLInputElement;
		const value = targetElement.value;
		// It should change if the value ends with a comma and then a space, and has a value besides that
		const shouldChange = value !== ', ' && value.endsWith(', ');
		if (!shouldChange) {
			return;
		}
		this.addSampleText(value.replace(/, $/gi, ''));
	}
}
