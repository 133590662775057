/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	Route,
	Router,
	RouterStateSnapshot,
	UrlSegment,
	UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard {
	constructor(
		private authService: AuthService,
		private router: Router,
	) {}

	/**
	 * Check if the user is logged in, used in app-routing for lazy loaded modules
	 */
	canLoad(
		route: Route,
		segments: UrlSegment[],
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		const isLoggedIn = this.authService.currentUserValue !== null;

		if (!isLoggedIn) {
			let redirectUrl = '';

			segments.forEach((s) => (redirectUrl += '/' + s.path));

			this.router.navigateByUrl('/login', {
				state: { jwtInvalid: true, redirectUrl: redirectUrl },
			});
		}

		return isLoggedIn;
	}

	/**
	 * Validates if the logged in user has the right permission to access the route
	 */
	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		const hasUserPermission = this.authService.doesUserHasPermission(
			route.data.features,
		);

		if (!hasUserPermission) {
			this.router.navigateByUrl('/no-access');
		}

		return hasUserPermission;
	}
}
