/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AgreementFormTemplateRepresentation } from '../models/agreement-form-template-representation';

@Injectable({
	providedIn: 'root',
})
export class AgreementFormTemplateControllerService extends BaseService {
	constructor(
		config: ApiConfiguration,
		http: HttpClient
	) {
		super(config, http);
	}

  /**
   * Path part for operation saveTemplate
   */
  static readonly SaveTemplatePath = '/api/admin/agreementFormTemplate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveTemplate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   *
   * @deprecated
   */
  saveTemplate$Response(params: {
    body: AgreementFormTemplateRepresentation
  }): Observable<StrictHttpResponse<AgreementFormTemplateRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, AgreementFormTemplateControllerService.SaveTemplatePath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AgreementFormTemplateRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveTemplate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   *
   * @deprecated
   */
  saveTemplate(params: {
    body: AgreementFormTemplateRepresentation
  }): Observable<AgreementFormTemplateRepresentation> {

    return this.saveTemplate$Response(params).pipe(
      map((r: StrictHttpResponse<AgreementFormTemplateRepresentation>) => r.body as AgreementFormTemplateRepresentation)
    );
  }

  /**
   * Path part for operation saveTemplate1
   */
  static readonly SaveTemplate1Path = '/api/admin/agreementFormTemplate/{type}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveTemplate1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveTemplate1$Response(params: {
    type: 'CONSENTFORM' | 'MTA';
    body: AgreementFormTemplateRepresentation
  }): Observable<StrictHttpResponse<AgreementFormTemplateRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, AgreementFormTemplateControllerService.SaveTemplate1Path, 'put');
    if (params) {
      rb.path('type', params.type, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AgreementFormTemplateRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveTemplate1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveTemplate1(params: {
    type: 'CONSENTFORM' | 'MTA';
    body: AgreementFormTemplateRepresentation
  }): Observable<AgreementFormTemplateRepresentation> {

    return this.saveTemplate1$Response(params).pipe(
      map((r: StrictHttpResponse<AgreementFormTemplateRepresentation>) => r.body as AgreementFormTemplateRepresentation)
    );
  }

  /**
   * Path part for operation getTemplate
   */
  static readonly GetTemplatePath = '/api/public/agreementFormTemplate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTemplate()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  getTemplate$Response(params?: {
  }): Observable<StrictHttpResponse<AgreementFormTemplateRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, AgreementFormTemplateControllerService.GetTemplatePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AgreementFormTemplateRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  getTemplate(params?: {
  }): Observable<AgreementFormTemplateRepresentation> {

    return this.getTemplate$Response(params).pipe(
      map((r: StrictHttpResponse<AgreementFormTemplateRepresentation>) => r.body as AgreementFormTemplateRepresentation)
    );
  }

  /**
   * Path part for operation getTemplate1
   */
  static readonly GetTemplate1Path = '/api/public/agreementFormTemplate/{type}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTemplate1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTemplate1$Response(params: {
    type: 'CONSENTFORM' | 'MTA';
  }): Observable<StrictHttpResponse<AgreementFormTemplateRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, AgreementFormTemplateControllerService.GetTemplate1Path, 'get');
    if (params) {
      rb.path('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AgreementFormTemplateRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTemplate1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTemplate1(params: {
    type: 'CONSENTFORM' | 'MTA';
  }): Observable<AgreementFormTemplateRepresentation> {

    return this.getTemplate1$Response(params).pipe(
      map((r: StrictHttpResponse<AgreementFormTemplateRepresentation>) => r.body as AgreementFormTemplateRepresentation)
    );
  }

}
