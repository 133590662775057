/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/modules/auth/auth.service';
import {
	ProfileRepresentation,
	RoleRepresentation,
} from 'src/app/modules/generated/models';
import { UserControllerService } from 'src/app/modules/generated/services';
/**
 * What the modal returns to the ref
 */
export interface RoleSelectionModalResult {
	buttonChoice: boolean;
	text: ProfileRepresentation;
}

@Component({
	selector: 'palga-role-selection-modal',
	templateUrl: './role-selection-modal.component.html',
	styleUrls: ['./role-selection-modal.component.scss'],
})
export class RoleSelectionModalComponent implements OnInit, OnDestroy {
	/**'
	 * Holds all roles this user has
	 */
	public userRoles$: Observable<RoleRepresentation[]>;

	/**
	 * Holds the current user
	 */
	public user: ProfileRepresentation;

	/**
	 * To end subscriptions
	 */
	private destroy$ = new Subject<boolean>();

	constructor(
		private userService: UserControllerService,
		private auth: AuthService,
		public activeModal: NgbActiveModal,
	) {}

	ngOnInit(): void {
		this.getUserAndRoles();
	}

	ngOnDestroy() {
		this.destroy$.next(true);
		this.destroy$.complete();
	}

	getUserAndRoles(): void {
		this.userRoles$ = this.userService.getUser().pipe(
			tap((user) => {
				// Users current role is never synced to the backend, so get it from memory
				user.currentRole = this.auth.currentUserValue.currentRole;
				this.user = user;
			}),
			map((user) => [...user.roles.sort((a, b) => a.id - b.id)]),
		);
	}

	/**
	 * Called when user selects a new role
	 * @param role Selected role
	 */
	handleRoleSelection(role: string): void {
		this.user = {
			...this.user,
			currentRole: role,
		};
	}
}
