<!--
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
-->
<footer
	class="container-fluid fixed-bottom"
	[ngClass]="{ 'dntp-page': isDntpPage }"
>
	<p class="text-center">
		<ng-container *ngIf="isDntpPage; else isPalga">
			{{ footerString }}
		</ng-container>
		<ng-template #isPalga>
			<a
				href="http://www.palga.nl/"
				target="_blank"
			>
				{{ footerString }}
			</a>
		</ng-template>
		&nbsp; | &nbsp; &copy; 2017–{{ currentYear }}
		<!-- <a href="https://github.com/thehyve/dntp-portal" target="_blank">{{'Source' | translate}}</a> &nbsp; | &nbsp; -->
		<!-- {{ 'Source' | translate }}  -->
		&nbsp; | &nbsp;
		{{ version + ' (' + commit + ')' }}
	</p>
</footer>
