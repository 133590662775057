/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AuthService, permissions } from 'src/app/modules/auth/auth.service';
import { environment } from 'src/environments/environment';
import { downloadFile, zeroFill } from '../../util';

@Component({
	selector: 'palga-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
	constructor(
		private authService: AuthService,
		private http: HttpClient,
	) {}

	ngOnInit(): void {}

	/**
	 * Call backend for the request CSV
	 */
	downloadExportRequests() {
		this.http
			.get(environment.apiUrl + '/api/requests/csv', {
				headers: new HttpHeaders({
					Accept: 'text/csv',
				}),
				responseType: 'text',
				params: {
					roleName: this.authService.getRole(),
				},
			})
			.subscribe(
				(data) => {
					this.downloadCsv(data);
				},
				(error) => {
					console.error(error);
				},
			);
	}

	/**
	 * Creates a link element and triggers click
	 */
	downloadCsv(csvData) {
		// construct filename
		const date = new Date();
		const filename = [
			'requests',
			date.getFullYear(),
			zeroFill(date.getMonth() + 1),
			zeroFill(date.getDay()),
		].join('_');

		downloadFile(csvData, filename);
	}

	/**
	 * Check if the user has one of the features
	 * @param features Features to check
	 */
	checkUserAccess(features: string[]): boolean {
		return this.authService.doesUserHasPermission(features);
	}

	/**
	 * whether the user is allowed to view requests.
	 */
	canViewRequests() {
		return this.checkUserAccess([permissions.REQUESTS]);
	}

	/**
	 * whether the user is allowed to view lab requests.
	 */
	canViewLabRequests() {
		return this.checkUserAccess([permissions.LAB_REQUESTS]);
	}

	/**
	 * whether the user is allowed to view samples.
	 */
	canViewSamples() {
		return this.checkUserAccess([permissions.SAMPLES]);
	}

	/**
	 * whether the user is allowed to view management options.
	 */
	canViewManagement() {
		return this.checkUserAccess([
			permissions.USERS,
			permissions.LABS,
			permissions.AGREEMENT_FORM,
			permissions.ACCESS_LOGS,
		]);
	}

	/**
	 * whether the user is allowed to view users.
	 */
	canViewUsers() {
		return this.checkUserAccess([permissions.USERS]);
	}

	/**
	 * whether the user is allowed to view labs.
	 */
	canViewLabs() {
		return this.checkUserAccess([permissions.LABS]);
	}

	/**
	 * whether the user is allowed to view form templates.
	 */
	canEditAgreementFormTemplate() {
		return this.checkUserAccess([permissions.AGREEMENT_FORM]);
	}

	/**
	 * whether the user is allowed to view access logs.
	 */
	canViewAccessLogs() {
		return this.checkUserAccess([permissions.ACCESS_LOGS]);
	}

	/**
	 * whether the user is allowed to view my lab menu item.
	 */
	canViewOwnLab() {
		return this.checkUserAccess([permissions.OWN_LAB]);
	}

	/**
	 * whether the user is allowed to view hub labs menu item.
	 */
	canViewHubLabs() {
		return this.checkUserAccess([permissions.HUB_LABS]);
	}

	/**
	 * whether the user is a lab user.
	 */
	isLabUser() {
		return this.authService.hasRole('lab_user');
	}
}
