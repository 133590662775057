/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'palga-confirm-with-text-input',
	templateUrl: './confirm-with-text-input.component.html',
	styleUrls: ['./confirm-with-text-input.component.scss'],
})
export class ConfirmWithTextInputComponent implements OnInit {
	messageContent: string;

	textFieldDescription: string;

	textFieldContent: string;

	constructor(public activeModal: NgbActiveModal) {}

	ngOnInit(): void {}
}
