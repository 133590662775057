/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'palga-status-modal',
	templateUrl: './status-modal.component.html',
	styleUrls: ['./status-modal.component.scss'],
})
export class StatusModalComponent implements OnInit {
	public message: string = '';

	public multiMessages: string[] = [];

	constructor(public activeModal: NgbActiveModal) {}

	ngOnInit(): void {}
}
