/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ExcerptListRepresentation } from '../models/excerpt-list-representation';
import { RequestRepresentation } from '../models/request-representation';

@Injectable({
	providedIn: 'root',
})
export class RequestFileControllerService extends BaseService {
	constructor(
		config: ApiConfiguration,
		http: HttpClient
	) {
		super(config, http);
	}

  /**
   * Path part for operation uploadMecAttachment
   */
  static readonly UploadMecAttachmentPath = '/api/requests/{id}/mecFiles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadMecAttachment()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadMecAttachment$Response(params: {
    id: string;
    flowFilename: string;
    flowTotalChunks: number;
    flowChunkNumber: number;
    flowIdentifier: string;
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<RequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, RequestFileControllerService.UploadMecAttachmentPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('flowFilename', params.flowFilename, {});
      rb.query('flowTotalChunks', params.flowTotalChunks, {});
      rb.query('flowChunkNumber', params.flowChunkNumber, {});
      rb.query('flowIdentifier', params.flowIdentifier, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadMecAttachment$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadMecAttachment(params: {
    id: string;
    flowFilename: string;
    flowTotalChunks: number;
    flowChunkNumber: number;
    flowIdentifier: string;
    body?: {
'file'?: Blob;
}
  }): Observable<RequestRepresentation> {

    return this.uploadMecAttachment$Response(params).pipe(
      map((r: StrictHttpResponse<RequestRepresentation>) => r.body as RequestRepresentation)
    );
  }

  /**
   * Path part for operation uploadInformedConsentFormAttachment
   */
  static readonly UploadInformedConsentFormAttachmentPath = '/api/requests/{id}/informedConsentFormFiles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadInformedConsentFormAttachment()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadInformedConsentFormAttachment$Response(params: {
    id: string;
    flowFilename: string;
    flowTotalChunks: number;
    flowChunkNumber: number;
    flowIdentifier: string;
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<RequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, RequestFileControllerService.UploadInformedConsentFormAttachmentPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('flowFilename', params.flowFilename, {});
      rb.query('flowTotalChunks', params.flowTotalChunks, {});
      rb.query('flowChunkNumber', params.flowChunkNumber, {});
      rb.query('flowIdentifier', params.flowIdentifier, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadInformedConsentFormAttachment$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadInformedConsentFormAttachment(params: {
    id: string;
    flowFilename: string;
    flowTotalChunks: number;
    flowChunkNumber: number;
    flowIdentifier: string;
    body?: {
'file'?: Blob;
}
  }): Observable<RequestRepresentation> {

    return this.uploadInformedConsentFormAttachment$Response(params).pipe(
      map((r: StrictHttpResponse<RequestRepresentation>) => r.body as RequestRepresentation)
    );
  }

  /**
   * Path part for operation uploadRequestAttachment
   */
  static readonly UploadRequestAttachmentPath = '/api/requests/{id}/files';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadRequestAttachment()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadRequestAttachment$Response(params: {
    id: string;
    flowFilename: string;
    flowTotalChunks: number;
    flowChunkNumber: number;
    flowIdentifier: string;
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<RequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, RequestFileControllerService.UploadRequestAttachmentPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('flowFilename', params.flowFilename, {});
      rb.query('flowTotalChunks', params.flowTotalChunks, {});
      rb.query('flowChunkNumber', params.flowChunkNumber, {});
      rb.query('flowIdentifier', params.flowIdentifier, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadRequestAttachment$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadRequestAttachment(params: {
    id: string;
    flowFilename: string;
    flowTotalChunks: number;
    flowChunkNumber: number;
    flowIdentifier: string;
    body?: {
'file'?: Blob;
}
  }): Observable<RequestRepresentation> {

    return this.uploadRequestAttachment$Response(params).pipe(
      map((r: StrictHttpResponse<RequestRepresentation>) => r.body as RequestRepresentation)
    );
  }

  /**
   * Path part for operation getExcerptList
   */
  static readonly GetExcerptListPath = '/api/requests/{id}/excerptList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getExcerptList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExcerptList$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<ExcerptListRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, RequestFileControllerService.GetExcerptListPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExcerptListRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getExcerptList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExcerptList(params: {
    id: string;
  }): Observable<ExcerptListRepresentation> {

    return this.getExcerptList$Response(params).pipe(
      map((r: StrictHttpResponse<ExcerptListRepresentation>) => r.body as ExcerptListRepresentation)
    );
  }

  /**
   * Path part for operation uploadExcerptList
   */
  static readonly UploadExcerptListPath = '/api/requests/{id}/excerptList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadExcerptList()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadExcerptList$Response(params: {
    id: string;
    flowFilename: string;
    flowTotalChunks: number;
    flowChunkNumber: number;
    flowIdentifier: string;
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, RequestFileControllerService.UploadExcerptListPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('flowFilename', params.flowFilename, {});
      rb.query('flowTotalChunks', params.flowTotalChunks, {});
      rb.query('flowChunkNumber', params.flowChunkNumber, {});
      rb.query('flowIdentifier', params.flowIdentifier, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadExcerptList$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadExcerptList(params: {
    id: string;
    flowFilename: string;
    flowTotalChunks: number;
    flowChunkNumber: number;
    flowIdentifier: string;
    body?: {
'file'?: Blob;
}
  }): Observable<number> {

    return this.uploadExcerptList$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation useExampleExcerptList
   */
  static readonly UseExampleExcerptListPath = '/api/requests/{id}/excerptList/useExample';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `useExampleExcerptList()` instead.
   *
   * This method doesn't expect any request body.
   */
  useExampleExcerptList$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, RequestFileControllerService.UseExampleExcerptListPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `useExampleExcerptList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  useExampleExcerptList(params: {
    id: string;
  }): Observable<number> {

    return this.useExampleExcerptList$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation uploadDataAttachment
   */
  static readonly UploadDataAttachmentPath = '/api/requests/{id}/dataFiles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadDataAttachment()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadDataAttachment$Response(params: {
    id: string;
    flowFilename: string;
    flowTotalChunks: number;
    flowChunkNumber: number;
    flowIdentifier: string;
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<RequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, RequestFileControllerService.UploadDataAttachmentPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('flowFilename', params.flowFilename, {});
      rb.query('flowTotalChunks', params.flowTotalChunks, {});
      rb.query('flowChunkNumber', params.flowChunkNumber, {});
      rb.query('flowIdentifier', params.flowIdentifier, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadDataAttachment$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadDataAttachment(params: {
    id: string;
    flowFilename: string;
    flowTotalChunks: number;
    flowChunkNumber: number;
    flowIdentifier: string;
    body?: {
'file'?: Blob;
}
  }): Observable<RequestRepresentation> {

    return this.uploadDataAttachment$Response(params).pipe(
      map((r: StrictHttpResponse<RequestRepresentation>) => r.body as RequestRepresentation)
    );
  }

  /**
   * Path part for operation uploadAgreementAttachment
   */
  static readonly UploadAgreementAttachmentPath = '/api/requests/{id}/agreementFiles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadAgreementAttachment()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadAgreementAttachment$Response(params: {
    id: string;
    flowFilename: string;
    flowTotalChunks: number;
    flowChunkNumber: number;
    flowIdentifier: string;
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<RequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, RequestFileControllerService.UploadAgreementAttachmentPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('flowFilename', params.flowFilename, {});
      rb.query('flowTotalChunks', params.flowTotalChunks, {});
      rb.query('flowChunkNumber', params.flowChunkNumber, {});
      rb.query('flowIdentifier', params.flowIdentifier, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadAgreementAttachment$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadAgreementAttachment(params: {
    id: string;
    flowFilename: string;
    flowTotalChunks: number;
    flowChunkNumber: number;
    flowIdentifier: string;
    body?: {
'file'?: Blob;
}
  }): Observable<RequestRepresentation> {

    return this.uploadAgreementAttachment$Response(params).pipe(
      map((r: StrictHttpResponse<RequestRepresentation>) => r.body as RequestRepresentation)
    );
  }

  /**
   * Path part for operation getFile
   */
  static readonly GetFilePath = '/api/requests/{id}/files/{attachmentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFile$Response(params: {
    id: string;
    attachmentId: number;
  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, RequestFileControllerService.GetFilePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('attachmentId', params.attachmentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFile(params: {
    id: string;
    attachmentId: number;
  }): Observable<Blob> {

    return this.getFile$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation deleteRequestAttachment
   */
  static readonly DeleteRequestAttachmentPath = '/api/requests/{id}/files/{attachmentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRequestAttachment()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRequestAttachment$Response(params: {
    id: string;
    attachmentId: number;
  }): Observable<StrictHttpResponse<RequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, RequestFileControllerService.DeleteRequestAttachmentPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('attachmentId', params.attachmentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteRequestAttachment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRequestAttachment(params: {
    id: string;
    attachmentId: number;
  }): Observable<RequestRepresentation> {

    return this.deleteRequestAttachment$Response(params).pipe(
      map((r: StrictHttpResponse<RequestRepresentation>) => r.body as RequestRepresentation)
    );
  }

  /**
   * Path part for operation downloadExcerptList
   */
  static readonly DownloadExcerptListPath = '/api/requests/{id}/excerptList/csv';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadExcerptList()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadExcerptList$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, RequestFileControllerService.DownloadExcerptListPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `downloadExcerptList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadExcerptList(params: {
    id: string;
  }): Observable<Blob> {

    return this.downloadExcerptList$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation removeMecAttachment
   */
  static readonly RemoveMecAttachmentPath = '/api/requests/{id}/mecFiles/{attachmentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeMecAttachment()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeMecAttachment$Response(params: {
    id: string;
    attachmentId: number;
  }): Observable<StrictHttpResponse<RequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, RequestFileControllerService.RemoveMecAttachmentPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('attachmentId', params.attachmentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeMecAttachment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeMecAttachment(params: {
    id: string;
    attachmentId: number;
  }): Observable<RequestRepresentation> {

    return this.removeMecAttachment$Response(params).pipe(
      map((r: StrictHttpResponse<RequestRepresentation>) => r.body as RequestRepresentation)
    );
  }

  /**
   * Path part for operation removeInformedConsentFormAttachment
   */
  static readonly RemoveInformedConsentFormAttachmentPath = '/api/requests/{id}/informedConsentFormFiles/{attachmentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeInformedConsentFormAttachment()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeInformedConsentFormAttachment$Response(params: {
    id: string;
    attachmentId: number;
  }): Observable<StrictHttpResponse<RequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, RequestFileControllerService.RemoveInformedConsentFormAttachmentPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('attachmentId', params.attachmentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeInformedConsentFormAttachment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeInformedConsentFormAttachment(params: {
    id: string;
    attachmentId: number;
  }): Observable<RequestRepresentation> {

    return this.removeInformedConsentFormAttachment$Response(params).pipe(
      map((r: StrictHttpResponse<RequestRepresentation>) => r.body as RequestRepresentation)
    );
  }

  /**
   * Path part for operation removeDataAttachment
   */
  static readonly RemoveDataAttachmentPath = '/api/requests/{id}/dataFiles/{attachmentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeDataAttachment()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeDataAttachment$Response(params: {
    id: string;
    attachmentId: number;
  }): Observable<StrictHttpResponse<RequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, RequestFileControllerService.RemoveDataAttachmentPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('attachmentId', params.attachmentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeDataAttachment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeDataAttachment(params: {
    id: string;
    attachmentId: number;
  }): Observable<RequestRepresentation> {

    return this.removeDataAttachment$Response(params).pipe(
      map((r: StrictHttpResponse<RequestRepresentation>) => r.body as RequestRepresentation)
    );
  }

  /**
   * Path part for operation removeAgreementAttachment
   */
  static readonly RemoveAgreementAttachmentPath = '/api/requests/{id}/agreementFiles/{attachmentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeAgreementAttachment()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeAgreementAttachment$Response(params: {
    id: string;
    attachmentId: number;
  }): Observable<StrictHttpResponse<RequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, RequestFileControllerService.RemoveAgreementAttachmentPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('attachmentId', params.attachmentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeAgreementAttachment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeAgreementAttachment(params: {
    id: string;
    attachmentId: number;
  }): Observable<RequestRepresentation> {

    return this.removeAgreementAttachment$Response(params).pipe(
      map((r: StrictHttpResponse<RequestRepresentation>) => r.body as RequestRepresentation)
    );
  }

}
