/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ApprovalVoteRepresentation } from '../models/approval-vote-representation';

@Injectable({
	providedIn: 'root',
})
export class ApprovalVoteControllerService extends BaseService {
	constructor(
		config: ApiConfiguration,
		http: HttpClient
	) {
		super(config, http);
	}

  /**
   * Path part for operation updateVote
   */
  static readonly UpdateVotePath = '/api/requests/{id}/approvalVotes/{voteId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateVote()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateVote$Response(params: {
    id: string;
    voteId: number;
    body: ApprovalVoteRepresentation
  }): Observable<StrictHttpResponse<ApprovalVoteRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, ApprovalVoteControllerService.UpdateVotePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('voteId', params.voteId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApprovalVoteRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateVote$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateVote(params: {
    id: string;
    voteId: number;
    body: ApprovalVoteRepresentation
  }): Observable<ApprovalVoteRepresentation> {

    return this.updateVote$Response(params).pipe(
      map((r: StrictHttpResponse<ApprovalVoteRepresentation>) => r.body as ApprovalVoteRepresentation)
    );
  }

  /**
   * Path part for operation getVotes
   */
  static readonly GetVotesPath = '/api/requests/{id}/approvalVotes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVotes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVotes$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<{
[key: string]: ApprovalVoteRepresentation;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ApprovalVoteControllerService.GetVotesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: ApprovalVoteRepresentation;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVotes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVotes(params: {
    id: string;
  }): Observable<{
[key: string]: ApprovalVoteRepresentation;
}> {

    return this.getVotes$Response(params).pipe(
      map((r: StrictHttpResponse<{
[key: string]: ApprovalVoteRepresentation;
}>) => r.body as {
[key: string]: ApprovalVoteRepresentation;
})
    );
  }

  /**
   * Path part for operation addVote
   */
  static readonly AddVotePath = '/api/requests/{id}/approvalVotes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addVote()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addVote$Response(params: {
    id: string;
    body: ApprovalVoteRepresentation
  }): Observable<StrictHttpResponse<ApprovalVoteRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, ApprovalVoteControllerService.AddVotePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApprovalVoteRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addVote$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addVote(params: {
    id: string;
    body: ApprovalVoteRepresentation
  }): Observable<ApprovalVoteRepresentation> {

    return this.addVote$Response(params).pipe(
      map((r: StrictHttpResponse<ApprovalVoteRepresentation>) => r.body as ApprovalVoteRepresentation)
    );
  }

}
