/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'palga-discard',
	templateUrl: './discard.component.html',
	styleUrls: ['./discard.component.scss'],
})
export class DiscardComponent implements OnInit {
	content: string;

	constructor(public activeModal: NgbActiveModal) {}

	ngOnInit(): void {}
}
