<!--
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
-->
<div class="modal-header">
	<button
		type="button"
		class="btn-close"
		aria-label="Close"
		(click)="activeModal.dismiss(false)"
	></button>
</div>
<div class="modal-body">
	<p *ngIf="!multiMessages?.length; else listMessages">
		{{ message | translate }}
	</p>
	<ng-template #listMessages>
		<ul class="ps-1">
			<li
				*ngFor="let mess of multiMessages"
				style="word-wrap: break-word"
			>
				{{ mess }}
			</li>
		</ul>
	</ng-template>
</div>
<div class="modal-footer">
	<palga-button
		color="primary"
		text="OK"
		(click)="activeModal.close(true)"
	></palga-button>
</div>
