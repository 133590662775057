/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ExcerptEntryRepresentation } from '../models/excerpt-entry-representation';
import { ExcerptListRepresentation } from '../models/excerpt-list-representation';
import { RequestRepresentation } from '../models/request-representation';

@Injectable({
	providedIn: 'root',
})
export class SelectionControllerService extends BaseService {
	constructor(
		config: ApiConfiguration,
		http: HttpClient
	) {
		super(config, http);
	}

  /**
   * Path part for operation updateExcerptSelection
   */
  static readonly UpdateExcerptSelectionPath = '/api/requests/{requestId}/excerpts/{id}/selection';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateExcerptSelection()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateExcerptSelection$Response(params: {
    requestId: string;
    id: string;
    body: ExcerptEntryRepresentation
  }): Observable<StrictHttpResponse<ExcerptEntryRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, SelectionControllerService.UpdateExcerptSelectionPath, 'put');
    if (params) {
      rb.path('requestId', params.requestId, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExcerptEntryRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateExcerptSelection$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateExcerptSelection(params: {
    requestId: string;
    id: string;
    body: ExcerptEntryRepresentation
  }): Observable<ExcerptEntryRepresentation> {

    return this.updateExcerptSelection$Response(params).pipe(
      map((r: StrictHttpResponse<ExcerptEntryRepresentation>) => r.body as ExcerptEntryRepresentation)
    );
  }

  /**
   * Path part for operation submitExcerptSelection
   */
  static readonly SubmitExcerptSelectionPath = '/api/requests/{id}/submitExcerptSelection';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `submitExcerptSelection()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  submitExcerptSelection$Response(params: {
    id: string;
    body: RequestRepresentation
  }): Observable<StrictHttpResponse<RequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, SelectionControllerService.SubmitExcerptSelectionPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `submitExcerptSelection$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  submitExcerptSelection(params: {
    id: string;
    body: RequestRepresentation
  }): Observable<RequestRepresentation> {

    return this.submitExcerptSelection$Response(params).pipe(
      map((r: StrictHttpResponse<RequestRepresentation>) => r.body as RequestRepresentation)
    );
  }

  /**
   * Path part for operation getSelection
   */
  static readonly GetSelectionPath = '/api/requests/{id}/selection';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSelection()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSelection$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<ExcerptListRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, SelectionControllerService.GetSelectionPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExcerptListRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSelection$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSelection(params: {
    id: string;
  }): Observable<ExcerptListRepresentation> {

    return this.getSelection$Response(params).pipe(
      map((r: StrictHttpResponse<ExcerptListRepresentation>) => r.body as ExcerptListRepresentation)
    );
  }

  /**
   * Path part for operation updateExcerptListSelection
   */
  static readonly UpdateExcerptListSelectionPath = '/api/requests/{id}/selection';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateExcerptListSelection()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateExcerptListSelection$Response(params: {
    id: string;
    body: ExcerptListRepresentation
  }): Observable<StrictHttpResponse<ExcerptListRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, SelectionControllerService.UpdateExcerptListSelectionPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExcerptListRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateExcerptListSelection$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateExcerptListSelection(params: {
    id: string;
    body: ExcerptListRepresentation
  }): Observable<ExcerptListRepresentation> {

    return this.updateExcerptListSelection$Response(params).pipe(
      map((r: StrictHttpResponse<ExcerptListRepresentation>) => r.body as ExcerptListRepresentation)
    );
  }

  /**
   * Path part for operation selectAll
   */
  static readonly SelectAllPath = '/api/requests/{id}/selectAll';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `selectAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  selectAll$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<RequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, SelectionControllerService.SelectAllPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `selectAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  selectAll(params: {
    id: string;
  }): Observable<RequestRepresentation> {

    return this.selectAll$Response(params).pipe(
      map((r: StrictHttpResponse<RequestRepresentation>) => r.body as RequestRepresentation)
    );
  }

  /**
   * Path part for operation setExcerptSelectionApproval
   */
  static readonly SetExcerptSelectionApprovalPath = '/api/requests/{id}/excerptSelectionApproval';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setExcerptSelectionApproval()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setExcerptSelectionApproval$Response(params: {
    id: string;
    body: RequestRepresentation
  }): Observable<StrictHttpResponse<RequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, SelectionControllerService.SetExcerptSelectionApprovalPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setExcerptSelectionApproval$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setExcerptSelectionApproval(params: {
    id: string;
    body: RequestRepresentation
  }): Observable<RequestRepresentation> {

    return this.setExcerptSelectionApproval$Response(params).pipe(
      map((r: StrictHttpResponse<RequestRepresentation>) => r.body as RequestRepresentation)
    );
  }

  /**
   * Path part for operation downloadExcerptSelection
   */
  static readonly DownloadExcerptSelectionPath = '/api/requests/{id}/selection/csv';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadExcerptSelection()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadExcerptSelection$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, SelectionControllerService.DownloadExcerptSelectionPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `downloadExcerptSelection$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadExcerptSelection(params: {
    id: string;
  }): Observable<Blob> {

    return this.downloadExcerptSelection$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation uploadExcerptSelection
   */
  static readonly UploadExcerptSelectionPath = '/api/requests/{id}/selection/csv';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadExcerptSelection()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  uploadExcerptSelection$Response(params: {
    id: string;
    flowFilename: string;
    flowTotalChunks: number;
    flowChunkNumber: number;
    flowIdentifier: string;
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, SelectionControllerService.UploadExcerptSelectionPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('flowFilename', params.flowFilename, {});
      rb.query('flowTotalChunks', params.flowTotalChunks, {});
      rb.query('flowChunkNumber', params.flowChunkNumber, {});
      rb.query('flowIdentifier', params.flowIdentifier, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadExcerptSelection$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  uploadExcerptSelection(params: {
    id: string;
    flowFilename: string;
    flowTotalChunks: number;
    flowChunkNumber: number;
    flowIdentifier: string;
    body?: {
'file'?: Blob;
}
  }): Observable<number> {

    return this.uploadExcerptSelection$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

}
