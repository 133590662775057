/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import {
	CommentRepresentation,
	ProfileRepresentation,
} from '../../../generated/models';
import { CommentsService, CommentType } from '../../services/comments.service';
import { getName as sharedGetName } from '../../util';
import { AuthService } from '../../../auth/auth.service';

@Component({
	selector: 'palga-comments',
	templateUrl: './comments.component.html',
	styleUrls: ['./comments.component.scss'],
})
export class CommentsComponent implements OnInit {
	@ViewChild('f', { static: false }) commentForm: NgForm;
	@Input() comments: CommentRepresentation[];
	@Input() commentEditVisibility = {};
	@Input() commentType: CommentType;
	@Input() id: string;
	@Input() allowAdd: boolean = true;
	@Input() allowNotification: boolean = false;
	dataLoading: boolean;
	editComment: CommentRepresentation = { notificationRequested: true };
	error: string;
	placeholder: string = 'note';

	constructor(
		private sharedCommentService: CommentsService,
		private authService: AuthService,
	) {}

	ngOnInit(): void {
		if (this.commentType === CommentType.APPROVAL) {
			this.placeholder = 'comment';
		}
	}

	startEditing(comment: CommentRepresentation) {
		this.commentEditVisibility[comment.id] = 1;
	}

	addComment() {
		if (!this.dataLoading) {
			this.dataLoading = true;
			this.sharedCommentService
				.post(this.commentType, {
					id: this.id,
					body: {
						contents: this.commentForm.value.commentText,
						notificationRequested: this.allowNotification
							? this.editComment.notificationRequested
							: false,
					},
				})
				.subscribe(
					(result) => {
						this.comments.push(result);
						this.dataLoading = false;
						this.commentForm.reset();
					},
					(response) => {
						this.error = response.statusText;
						this.dataLoading = false;
					},
				);
		}
	}

	updateComment(body: CommentRepresentation, updatedComment: string = null) {
		if (!this.dataLoading) {
			this.dataLoading = true;
			this.sharedCommentService
				.put(this.commentType, {
					id:
						this.commentType == CommentType.LABREQUEST
							? this.id
							: body.processInstanceId,
					commentId: body.id,
					body: {
						...body,
						contents: updatedComment,
						notificationRequested: this.allowNotification
							? this.editComment.notificationRequested
							: false,
					},
				})
				.subscribe(
					(result) => {
						var index = this.comments.indexOf(body);
						this.comments[index] = result;
						this.commentEditVisibility[body.id] = 0;
						this.dataLoading = false;
					},
					(response) => {
						this.error = this.error + response.data.message + '\n';
						this.dataLoading = false;
					},
				);
		}
	}

	removeComment(comment: CommentRepresentation) {
		if (!this.dataLoading) {
			this.dataLoading = true;
			this.sharedCommentService
				.delete(this.commentType, {
					id:
						this.commentType == CommentType.LABREQUEST
							? this.id
							: comment.processInstanceId,
					commentId: comment.id,
				})
				.subscribe(
					() => {
						this.dataLoading = false;
						this.comments.splice(this.comments.indexOf(comment), 1);
					},
					(response) => {
						this.error = this.error + response.data.message + '\n';
						this.dataLoading = false;
					},
				);
		}
	}

	getName(user: ProfileRepresentation): string {
		return sharedGetName(user);
	}

	/**
	 * Check if the userId argument is the same as the current logged in user ID
	 */
	isCurrentUser(userId: number) {
		return userId == this.authService.currentUserValue.id;
	}

	isLabUser(): boolean {
		return this.authService.hasRole('lab_user');
	}

	isHubUser(): boolean {
		return this.authService.hasRole('hub_user');
	}

	isRequester(): boolean {
		return this.authService.hasRole('requester');
	}

	isPalgaUser(): boolean {
		return this.authService.hasRole('palga');
	}

	canSendNotifiction(): boolean {
		return (
			this.allowNotification &&
			(this.isLabUser() ||
				this.isHubUser() ||
				this.isRequester() ||
				this.isPalgaUser())
		);
	}

	/**
	 * Called when user clicks the save button or ctrl+enter from textarea
	 */
	saveComment(
		comment: CommentRepresentation,
		commentText?: string,
		isUpdate?: boolean,
	) {
		if (isUpdate) {
			this.updateComment(comment, commentText);
		} else {
			this.addComment();
		}
	}
}
