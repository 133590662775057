<!--
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
-->
<address *ngIf="contactData">
	<span *ngIf="contactData.address1">
		{{ contactData.address1 }} <br />
	</span>
	<span *ngIf="contactData.address2">
		{{ contactData.address2 }} <br />
	</span>
	<span *ngIf="contactData.postalCode">
		{{ contactData.postalCode }}&nbsp;
	</span>
	<span *ngIf="contactData.city">
		{{ contactData.city }}
	</span>
	<br *ngIf="contactData.postalCode || contactData.city" />

	<span *ngIf="contactData.telephone">
		<span><i class="bi bi-telephone"></i></span>
		{{ contactData.telephone }}
		<br />
	</span>
	<span *ngIf="contactData.email">
		<span><i class="bi bi-envelope"></i></span>&nbsp;
		<a
			class="no-print-url"
			href="mailto:{{ contactData.email }}"
		>
			{{ contactData.email }}
		</a>
	</span>
</address>
