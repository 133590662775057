/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { storeJwt } from '../shared/constants';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { AuthService } from 'src/app/modules/auth/auth.service';
import { Router } from '@angular/router';
import jwtDecode from 'jwt-decode';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
	constructor(
		private authService: AuthService,
		private router: Router,
		private modalService: NgbModal,
	) {}

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler,
	): Observable<HttpEvent<any>> {
		const jwtToken = localStorage.getItem(storeJwt);
		if (jwtToken && request.url.startsWith(environment.apiUrl)) {
			request = request.clone({
				headers: request.headers.set(
					'Authorization',
					'Bearer ' + jwtToken,
				),
			});
		}

		return next.handle(request).pipe(
			catchError((err) => {
				if (err.status && err.status === 401) {
					this.authService.logout();
					const now = Date.now() / 1000;

					if (jwtToken && now > jwtDecode(jwtToken)['exp']) {
						this.modalService.dismissAll();
						this.router.navigateByUrl('/login', {
							state: {
								redirectUrl: this.router.url,
								jwtInvalid: true,
								message:
									'You have been logged out because your access token has expired. Please log in again if you wish to continue.',
							},
						});
					} else {
						this.modalService.dismissAll();
						this.router.navigateByUrl('/login', {
							state: {
								redirectUrl: this.router.url,
								jwtInvalid: true,
							},
						});
					}
				}

				return throwError(err);
			}),
		);
	}
}
