/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Lab } from '../models/lab';
import { ProfileRepresentation } from '../models/profile-representation';

@Injectable({
	providedIn: 'root',
})
export class ProfileControllerService extends BaseService {
	constructor(
		config: ApiConfiguration,
		http: HttpClient
	) {
		super(config, http);
	}

  /**
   * Path part for operation getOwnProfile
   */
  static readonly GetOwnProfilePath = '/api/profile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOwnProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOwnProfile$Response(params?: {
  }): Observable<StrictHttpResponse<ProfileRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileControllerService.GetOwnProfilePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProfileRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOwnProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOwnProfile(params?: {
  }): Observable<ProfileRepresentation> {

    return this.getOwnProfile$Response(params).pipe(
      map((r: StrictHttpResponse<ProfileRepresentation>) => r.body as ProfileRepresentation)
    );
  }

  /**
   * Path part for operation putOwnProfile
   */
  static readonly PutOwnProfilePath = '/api/profile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putOwnProfile()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putOwnProfile$Response(params: {
    body: ProfileRepresentation
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileControllerService.PutOwnProfilePath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putOwnProfile$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putOwnProfile(params: {
    body: ProfileRepresentation
  }): Observable<void> {

    return this.putOwnProfile$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation changeEmail
   */
  static readonly ChangeEmailPath = '/api/profile/change/{token}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeEmail()` instead.
   *
   * This method doesn't expect any request body.
   */
  changeEmail$Response(params: {
    token: string;
  }): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileControllerService.ChangeEmailPath, 'get');
    if (params) {
      rb.path('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `changeEmail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  changeEmail(params: {
    token: string;
  }): Observable<{
}> {

    return this.changeEmail$Response(params).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation getProfileHubLabs
   */
  static readonly GetProfileHubLabsPath = '/api/hublabs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProfileHubLabs()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfileHubLabs$Response(params?: {
  }): Observable<StrictHttpResponse<Array<Lab>>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileControllerService.GetProfileHubLabsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Lab>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getProfileHubLabs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfileHubLabs(params?: {
  }): Observable<Array<Lab>> {

    return this.getProfileHubLabs$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Lab>>) => r.body as Array<Lab>)
    );
  }

}
