/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { Lab, LabRepresentation } from 'src/app/modules/generated/models';
import { LabProfileControllerService } from 'src/app/modules/generated/services';

@Component({
	selector: 'palga-lab-list',
	templateUrl: './lab-list.component.html',
	styleUrls: ['./lab-list.component.scss'],
})
export class LabListComponent implements OnInit, OnChanges {
	/**-
	 * Can be used with [(selectedLab)]
	 * Takes preference over selectedLLabID
	 */
	@Input() selectedLab: LabRepresentation;
	/**
	 * Can be used with [(selectedLab)]
	 */
	@Output() selectedLabChange = new EventEmitter<LabRepresentation>();
	/**
	 * Use when all you have is an id
	 * Ignored if selectedLab is set
	 */
	@Input() selectedLabID: number;
	/**
	 * To display as a dropdown, set this to one
	 */
	@Input() selectSize = 5;
	/**
	 * Disable select
	 */
	@Input() disable: boolean;
	/**
	 * Should select have a value
	 */
	@Input() isRequired: boolean;
	/**
	 * Show value only, no dropdown or interaction
	 */
	@Input() displayOnly: boolean;

	labs$: Observable<LabRepresentation[]>;

	private labs: LabRepresentation[];

	constructor(private labService: LabProfileControllerService) {}

	ngOnInit(): void {
		this.getLabs();
		if (this.displayOnly) {
			this.selectSize = 1;
			this.disable = true;
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.selectedLabID && !changes.selectedLabID.firstChange) {
			this.setSelectedLab(changes.selectedLabID.currentValue);
		}
		if (changes.disable && !changes.disable.firstChange) {
			this.disable = changes.disable.currentValue;
		}
		if (changes.displayOnly && !changes.displayOnly.firstChange) {
			this.selectSize = changes.displayOnly.currentValue ? 1 : null;
			this.disable = changes.displayOnly.currentValue;
		}
	}

	getLabs() {
		this.labs$ = this.labService.getList().pipe(
			take(1),
			catchError((error) => {
				console.error('There was a problem fetching labs: ', error);
				return of([]);
			}),
			tap((labs) => {
				this.labs = labs;
				// If we have an id and no selected lab, find lab and set it
				if (this.selectedLabID !== undefined && !this.selectedLab) {
					this.setSelectedLab(this.selectedLabID);
				}
			}),
		);
	}

	emitChange(selectedLabId: string) {
		const selected = this.labs.find((lab) => lab.id === +selectedLabId);
		this.selectedLabChange.emit(selected);
	}

	public setSelectedLab(labID: LabRepresentation['id']) {
		this.selectedLab = this.labs.find((lab) => lab.id === +labID);
	}
}
