/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { LabRequestRepresentation } from '../models/lab-request-representation';
import { ReturnDateRepresentation } from '../models/return-date-representation';

@Injectable({
	providedIn: 'root',
})
export class LabRequestControllerService extends BaseService {
	constructor(
		config: ApiConfiguration,
		http: HttpClient
	) {
		super(config, http);
	}

  /**
   * Path part for operation getLabRequest
   */
  static readonly GetLabRequestPath = '/api/labrequests/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLabRequest()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLabRequest$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<LabRequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, LabRequestControllerService.GetLabRequestPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabRequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLabRequest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLabRequest(params: {
    id: number;
  }): Observable<LabRequestRepresentation> {

    return this.getLabRequest$Response(params).pipe(
      map((r: StrictHttpResponse<LabRequestRepresentation>) => r.body as LabRequestRepresentation)
    );
  }

  /**
   * Path part for operation update1
   */
  static readonly Update1Path = '/api/labrequests/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `update1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update1$Response(params: {
    id: number;
    body: LabRequestRepresentation
  }): Observable<StrictHttpResponse<LabRequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, LabRequestControllerService.Update1Path, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabRequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `update1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update1(params: {
    id: number;
    body: LabRequestRepresentation
  }): Observable<LabRequestRepresentation> {

    return this.update1$Response(params).pipe(
      map((r: StrictHttpResponse<LabRequestRepresentation>) => r.body as LabRequestRepresentation)
    );
  }

  /**
   * Path part for operation undoReject
   */
  static readonly UndoRejectPath = '/api/labrequests/{id}/undoreject';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `undoReject()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  undoReject$Response(params: {
    id: number;
    body: LabRequestRepresentation
  }): Observable<StrictHttpResponse<LabRequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, LabRequestControllerService.UndoRejectPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabRequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `undoReject$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  undoReject(params: {
    id: number;
    body: LabRequestRepresentation
  }): Observable<LabRequestRepresentation> {

    return this.undoReject$Response(params).pipe(
      map((r: StrictHttpResponse<LabRequestRepresentation>) => r.body as LabRequestRepresentation)
    );
  }

  /**
   * Path part for operation undoApprove
   */
  static readonly UndoApprovePath = '/api/labrequests/{id}/undoapprove';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `undoApprove()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  undoApprove$Response(params: {
    id: number;
    body: LabRequestRepresentation
  }): Observable<StrictHttpResponse<LabRequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, LabRequestControllerService.UndoApprovePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabRequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `undoApprove$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  undoApprove(params: {
    id: number;
    body: LabRequestRepresentation
  }): Observable<LabRequestRepresentation> {

    return this.undoApprove$Response(params).pipe(
      map((r: StrictHttpResponse<LabRequestRepresentation>) => r.body as LabRequestRepresentation)
    );
  }

  /**
   * Path part for operation unclaim1
   */
  static readonly Unclaim1Path = '/api/labrequests/{id}/unclaim';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unclaim1()` instead.
   *
   * This method doesn't expect any request body.
   */
  unclaim1$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<LabRequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, LabRequestControllerService.Unclaim1Path, 'put');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabRequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `unclaim1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unclaim1(params: {
    id: number;
  }): Observable<LabRequestRepresentation> {

    return this.unclaim1$Response(params).pipe(
      map((r: StrictHttpResponse<LabRequestRepresentation>) => r.body as LabRequestRepresentation)
    );
  }

  /**
   * Path part for operation sending
   */
  static readonly SendingPath = '/api/labrequests/{id}/sending';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sending()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sending$Response(params: {
    id: number;
    body: ReturnDateRepresentation
  }): Observable<StrictHttpResponse<LabRequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, LabRequestControllerService.SendingPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabRequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `sending$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sending(params: {
    id: number;
    body: ReturnDateRepresentation
  }): Observable<LabRequestRepresentation> {

    return this.sending$Response(params).pipe(
      map((r: StrictHttpResponse<LabRequestRepresentation>) => r.body as LabRequestRepresentation)
    );
  }

  /**
   * Path part for operation returning
   */
  static readonly ReturningPath = '/api/labrequests/{id}/returning';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `returning()` instead.
   *
   * This method doesn't expect any request body.
   */
  returning$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<LabRequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, LabRequestControllerService.ReturningPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabRequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `returning$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  returning(params: {
    id: number;
  }): Observable<LabRequestRepresentation> {

    return this.returning$Response(params).pipe(
      map((r: StrictHttpResponse<LabRequestRepresentation>) => r.body as LabRequestRepresentation)
    );
  }

  /**
   * Path part for operation updateReturnDate
   */
  static readonly UpdateReturnDatePath = '/api/labrequests/{id}/returndate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateReturnDate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateReturnDate$Response(params: {
    id: number;
    body: LabRequestRepresentation
  }): Observable<StrictHttpResponse<LabRequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, LabRequestControllerService.UpdateReturnDatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabRequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateReturnDate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateReturnDate(params: {
    id: number;
    body: LabRequestRepresentation
  }): Observable<LabRequestRepresentation> {

    return this.updateReturnDate$Response(params).pipe(
      map((r: StrictHttpResponse<LabRequestRepresentation>) => r.body as LabRequestRepresentation)
    );
  }

  /**
   * Path part for operation retourIncompleteReturned
   */
  static readonly RetourIncompleteReturnedPath = '/api/labrequests/{id}/retourincompletereturned';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `retourIncompleteReturned()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  retourIncompleteReturned$Response(params: {
    id: number;
    body: LabRequestRepresentation
  }): Observable<StrictHttpResponse<LabRequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, LabRequestControllerService.RetourIncompleteReturnedPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabRequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `retourIncompleteReturned$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  retourIncompleteReturned(params: {
    id: number;
    body: LabRequestRepresentation
  }): Observable<LabRequestRepresentation> {

    return this.retourIncompleteReturned$Response(params).pipe(
      map((r: StrictHttpResponse<LabRequestRepresentation>) => r.body as LabRequestRepresentation)
    );
  }

  /**
   * Path part for operation reject1
   */
  static readonly Reject1Path = '/api/labrequests/{id}/reject';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reject1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reject1$Response(params: {
    id: number;
    body: LabRequestRepresentation
  }): Observable<StrictHttpResponse<LabRequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, LabRequestControllerService.Reject1Path, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabRequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `reject1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reject1(params: {
    id: number;
    body: LabRequestRepresentation
  }): Observable<LabRequestRepresentation> {

    return this.reject1$Response(params).pipe(
      map((r: StrictHttpResponse<LabRequestRepresentation>) => r.body as LabRequestRepresentation)
    );
  }

  /**
   * Path part for operation received
   */
  static readonly ReceivedPath = '/api/labrequests/{id}/received';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `received()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  received$Response(params: {
    id: number;
    body: LabRequestRepresentation
  }): Observable<StrictHttpResponse<LabRequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, LabRequestControllerService.ReceivedPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabRequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `received$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  received(params: {
    id: number;
    body: LabRequestRepresentation
  }): Observable<LabRequestRepresentation> {

    return this.received$Response(params).pipe(
      map((r: StrictHttpResponse<LabRequestRepresentation>) => r.body as LabRequestRepresentation)
    );
  }

  /**
   * Path part for operation materiaalIncompleteReturned
   */
  static readonly MateriaalIncompleteReturnedPath = '/api/labrequests/{id}/materiaalincompletereturned';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `materiaalIncompleteReturned()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  materiaalIncompleteReturned$Response(params: {
    id: number;
    body: LabRequestRepresentation
  }): Observable<StrictHttpResponse<LabRequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, LabRequestControllerService.MateriaalIncompleteReturnedPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabRequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `materiaalIncompleteReturned$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  materiaalIncompleteReturned(params: {
    id: number;
    body: LabRequestRepresentation
  }): Observable<LabRequestRepresentation> {

    return this.materiaalIncompleteReturned$Response(params).pipe(
      map((r: StrictHttpResponse<LabRequestRepresentation>) => r.body as LabRequestRepresentation)
    );
  }

  /**
   * Path part for operation completeReturned
   */
  static readonly CompleteReturnedPath = '/api/labrequests/{id}/completereturned';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `completeReturned()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  completeReturned$Response(params: {
    id: number;
    body: LabRequestRepresentation
  }): Observable<StrictHttpResponse<LabRequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, LabRequestControllerService.CompleteReturnedPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabRequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `completeReturned$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  completeReturned(params: {
    id: number;
    body: LabRequestRepresentation
  }): Observable<LabRequestRepresentation> {

    return this.completeReturned$Response(params).pipe(
      map((r: StrictHttpResponse<LabRequestRepresentation>) => r.body as LabRequestRepresentation)
    );
  }

  /**
   * Path part for operation completeReportsOnly
   */
  static readonly CompleteReportsOnlyPath = '/api/labrequests/{id}/completereportsonly';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `completeReportsOnly()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  completeReportsOnly$Response(params: {
    id: number;
    body: LabRequestRepresentation
  }): Observable<StrictHttpResponse<LabRequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, LabRequestControllerService.CompleteReportsOnlyPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabRequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `completeReportsOnly$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  completeReportsOnly(params: {
    id: number;
    body: LabRequestRepresentation
  }): Observable<LabRequestRepresentation> {

    return this.completeReportsOnly$Response(params).pipe(
      map((r: StrictHttpResponse<LabRequestRepresentation>) => r.body as LabRequestRepresentation)
    );
  }

  /**
   * Path part for operation completeRejected
   */
  static readonly CompleteRejectedPath = '/api/labrequests/{id}/completerejected';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `completeRejected()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  completeRejected$Response(params: {
    id: number;
    body: LabRequestRepresentation
  }): Observable<StrictHttpResponse<LabRequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, LabRequestControllerService.CompleteRejectedPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabRequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `completeRejected$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  completeRejected(params: {
    id: number;
    body: LabRequestRepresentation
  }): Observable<LabRequestRepresentation> {

    return this.completeRejected$Response(params).pipe(
      map((r: StrictHttpResponse<LabRequestRepresentation>) => r.body as LabRequestRepresentation)
    );
  }

  /**
   * Path part for operation claim1
   */
  static readonly Claim1Path = '/api/labrequests/{id}/claim';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `claim1()` instead.
   *
   * This method doesn't expect any request body.
   */
  claim1$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<LabRequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, LabRequestControllerService.Claim1Path, 'put');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabRequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `claim1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  claim1(params: {
    id: number;
  }): Observable<LabRequestRepresentation> {

    return this.claim1$Response(params).pipe(
      map((r: StrictHttpResponse<LabRequestRepresentation>) => r.body as LabRequestRepresentation)
    );
  }

  /**
   * Path part for operation approve
   */
  static readonly ApprovePath = '/api/labrequests/{id}/approve';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `approve()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  approve$Response(params: {
    id: number;
    body: LabRequestRepresentation
  }): Observable<StrictHttpResponse<LabRequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, LabRequestControllerService.ApprovePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabRequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `approve$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  approve(params: {
    id: number;
    body: LabRequestRepresentation
  }): Observable<LabRequestRepresentation> {

    return this.approve$Response(params).pipe(
      map((r: StrictHttpResponse<LabRequestRepresentation>) => r.body as LabRequestRepresentation)
    );
  }

  /**
   * Path part for operation setStatus
   */
  static readonly SetStatusPath = '/api/labrequests/setStatus/{id}/{status}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  setStatus$Response(params: {
    id: number;
    status: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, LabRequestControllerService.SetStatusPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('status', params.status, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  setStatus(params: {
    id: number;
    status: string;
  }): Observable<string> {

    return this.setStatus$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getLabRequests
   */
  static readonly GetLabRequestsPath = '/api/labrequests';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLabRequests()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLabRequests$Response(params: {
    roleName: string;
  }): Observable<StrictHttpResponse<Array<LabRequestRepresentation>>> {

    const rb = new RequestBuilder(this.rootUrl, LabRequestControllerService.GetLabRequestsPath, 'get');
    if (params) {
      rb.query('roleName', params.roleName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LabRequestRepresentation>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLabRequests$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLabRequests(params: {
    roleName: string;
  }): Observable<Array<LabRequestRepresentation>> {

    return this.getLabRequests$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LabRequestRepresentation>>) => r.body as Array<LabRequestRepresentation>)
    );
  }

  /**
   * Path part for operation getLabRequestsFromLab
   */
  static readonly GetLabRequestsFromLabPath = '/api/labrequests/fromLab';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLabRequestsFromLab()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLabRequestsFromLab$Response(params?: {
  }): Observable<StrictHttpResponse<Array<LabRequestRepresentation>>> {

    const rb = new RequestBuilder(this.rootUrl, LabRequestControllerService.GetLabRequestsFromLabPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LabRequestRepresentation>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLabRequestsFromLab$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLabRequestsFromLab(params?: {
  }): Observable<Array<LabRequestRepresentation>> {

    return this.getLabRequestsFromLab$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LabRequestRepresentation>>) => r.body as Array<LabRequestRepresentation>)
    );
  }

  /**
   * Path part for operation getDetailedLabRequests
   */
  static readonly GetDetailedLabRequestsPath = '/api/labrequests/detailed';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDetailedLabRequests()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDetailedLabRequests$Response(params: {
    roleName: string;
  }): Observable<StrictHttpResponse<Array<LabRequestRepresentation>>> {

    const rb = new RequestBuilder(this.rootUrl, LabRequestControllerService.GetDetailedLabRequestsPath, 'get');
    if (params) {
      rb.query('roleName', params.roleName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LabRequestRepresentation>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDetailedLabRequests$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDetailedLabRequests(params: {
    roleName: string;
  }): Observable<Array<LabRequestRepresentation>> {

    return this.getDetailedLabRequests$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LabRequestRepresentation>>) => r.body as Array<LabRequestRepresentation>)
    );
  }

}
