/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
	selector: '[palgaTrim]',
})
export class TrimDirective {
	constructor(
		private elem: ElementRef,
		private ctrl: NgControl,
	) {}

	@HostListener('blur')
	onBlur() {
		// user setValue so validation occurs afterwards
		this.ctrl.control.setValue(this.elem.nativeElement.value.trim());
	}
}
