<!--
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
-->
<div
	class="lab-technique-selector"
	*ngIf="labTechMap && labTechArray"
>
	<div class="technique-line mb-3">
		<form #labTechForm="ngForm">
			<fieldset *ngFor="let technique of labTechArray">
				<div class="col-form-check">
					<input
						type="checkbox"
						class="me-2"
						[name]="technique"
						[id]="technique"
						[(ngModel)]="labTechMap[technique]"
					/>
					<label
						class="col-md-11"
						[for]="technique"
					>
						{{ technique | translate }}
					</label>
				</div>
				<div
					*ngIf="
						labTechMap[technique] &&
						labTechDeepMap[technique].options.length
					"
					class="technique-sub ms-4"
				>
					<div
						*ngFor="
							let subTechnique of labTechDeepMap[technique]
								.options
						"
						class="mb-3"
					>
						<ng-container
							*ngIf="
								!subTechnique.requiredFor ||
								labTechForm.controls[subTechnique.requiredFor]
									?.value
							"
						>
							<div class="sub-technique-group">
								<label
									*ngIf="subTechnique.label"
									[for]="subTechnique.label"
									class="technique-sub-label"
									>{{ subTechnique.label | translate }}</label
								>
								<input
									type="text"
									class="form-control"
									[name]="subTechnique.label || 'anders'"
									[(ngModel)]="subTechnique.value"
								/>
							</div>
							<div
								*ngIf="
									subTechnique.required && !subTechnique.value
								"
								class="mandatory-field d-flex justify-content-end"
							>
								{{ 'Required field' | translate }}
							</div>
						</ng-container>
					</div>
				</div>
				<div
					*ngIf="
						labTechDeepMap[technique].selected &&
						labTechDeepMap[technique].minOne &&
						shouldShowGroupError(labTechDeepMap[technique])
					"
					class="mandatory-field d-flex justify-content-center"
				>
					{{ 'Required field' | translate }}
				</div>
			</fieldset>
		</form>
	</div>
</div>
<span
	class="text-danger mt-1"
	*ngIf="!valid"
>
	{{ 'Required field' | translate }}
</span>
