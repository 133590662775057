/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
import { Lab } from 'src/app/modules/generated/models';

export const EmptyLabContactdata: Lab['contactData'] = {
	address1: '',
	address2: '',
	city: '',
	country: '',
	email: '',
	postalCode: '',
	stateProvince: '',
	telephone: '',
};
