<!--
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
-->
<div class="container text-center">
	<div
		*ngIf="isUploading"
		class="spinner-border text-primary"
	></div>
	<ngx-file-drag-drop
		activeBorderColor="#3F51B5"
		emptyPlaceholder="{{ 'Drop file or click to select' | translate }}"
		[formControl]="fileControl"
		[multiple]="canUploadMultiple"
		[displayFileSize]="true"
		[accept]="allowedMimeTypes"
		#fileDrop
	>
	</ngx-file-drag-drop>
	<ngb-alert
		type="danger"
		[dismissible]="false"
		*ngFor="let error of errors"
	>
		{{ error }}
	</ngb-alert>
	<palga-button
		*ngIf="!fileDrop.isEmpty"
		[disabled]="fileControl.value.length === 0 || this.errors.length > 0"
		text="{{ 'Upload' | translate }}"
		icon="upload"
		(click)="uploadFiles()"
	></palga-button>
</div>
