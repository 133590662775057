/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ExcerptEntryRepresentation } from '../models/excerpt-entry-representation';
import { ExcerptListRepresentation } from '../models/excerpt-list-representation';
import { RequestRepresentation } from '../models/request-representation';

@Injectable({
	providedIn: 'root',
})
export class SampleStatusControllerService extends BaseService {
	constructor(
		config: ApiConfiguration,
		http: HttpClient
	) {
		super(config, http);
	}

  /**
   * Path part for operation updateExcerptSelection
   */
  static readonly UpdateSampleStatusPath = '/api/labrequests/{id}/pathology/{sampleId}/status';
  updateSampleStatus(id,sampleId, status){
    const rb = new RequestBuilder(this.rootUrl, SampleStatusControllerService.UpdateSampleStatusPath, 'put');
    rb.path('id', id, {});
    rb.path('sampleId', sampleId, {});
    rb.body(status, 'application/json');
    return this.http.request(rb.build({
        responseType: 'json',
        accept: 'application/json'
      })).pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ExcerptEntryRepresentation>;
        })
      );

  }


}
