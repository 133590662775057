<!--
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
-->
<div class="modal-header">{{ 'Available roles' | translate }}</div>
<div class="modal-body">
	<form>
		<div
			class="form-field"
			*ngFor="let role of userRoles$ | async"
		>
			<label [for]="role.id">
				<input
					type="radio"
					class="me-2"
					name="currentRole"
					[id]="role.id"
					[checked]="role.name === user.currentRole"
					(change)="handleRoleSelection(role.name)"
				/>
				{{ 'role_' + role.name | translate }}
			</label>
		</div>
	</form>
</div>
<div class="modal-footer">
	<palga-button
		color="danger"
		text="Cancel"
		(click)="activeModal.close({ buttonChoice: false, text: user })"
	>
	</palga-button>

	<palga-button
		color="primary"
		text="OK"
		(click)="activeModal.close({ buttonChoice: true, text: user })"
	>
	</palga-button>
</div>
