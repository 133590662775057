<!--
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
-->
<div class="container-fluid p-0">
	<palga-header></palga-header>
	<main
		role="main"
		class=""
	>
		<router-outlet></router-outlet>
	</main>
	<palga-footer></palga-footer>
</div>
