/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
	UntypedFormGroup,
	UntypedFormBuilder,
	ValidatorFn,
	Validators,
} from '@angular/forms';
import { filter } from 'rxjs/operators';
import { Lab } from 'src/app/modules/generated/models';
import { EmptyLabContactdata } from './empty-lab-contactdata';

@Component({
	selector: 'palga-lab-contact-form',
	templateUrl: './lab-contact-form.component.html',
	styleUrls: ['./lab-contact-form.component.scss'],
})
export class LabContactFormComponent implements OnInit {
	/**
	 * Prefill lab
	 */
	@Input() setLabData: Lab['contactData'];
	/**
	 * Can be used with [(setLabData)] or (setLabDataChange)
	 */
	@Output() setLabDataChange = new EventEmitter<Lab['contactData']>();
	/**
	 * Emits the lab formgroup on every valuechange
	 */
	@Output() labFormChange = new EventEmitter<UntypedFormGroup>();
	/**
	 * Choose which fields should not be shown
	 */
	@Input() ignoreFields: Array<keyof Lab['contactData']> = [];
	/**
	 * Which fields should be required. If omitted all fields are required
	 */
	@Input() requiredFields: Array<keyof Lab['contactData']> = [];
	/**
	 * Set to true to make no fields required
	 */
	@Input() noRequired: boolean;

	labDataForm: UntypedFormGroup;

	constructor(private fb: UntypedFormBuilder) {}

	ngOnInit(): void {
		this.labDataForm = this.getLabDataForm(
			this.setLabData ? this.setLabData : EmptyLabContactdata,
		);

		this.labDataForm.valueChanges
			.pipe(filter((_) => this.labDataForm.dirty))
			.subscribe((values: Lab['contactData']) => {
				this.labFormChange.emit(this.labDataForm);
			});
	}

	getLabDataForm(labData?: Lab['contactData']): UntypedFormGroup {
		const fieldObject = {};
		const data = labData ? labData : EmptyLabContactdata;
		for (const field in data) {
			if (
				!this.ignoreFields.includes(field as keyof Lab['contactData'])
			) {
				fieldObject[field] = this.fb.control(
					labData[field] || '',
					this.getValidators(field as keyof Lab['contactData']),
				);
			}
		}
		return this.fb.group(fieldObject);
	}

	private getValidators(key: keyof Lab['contactData']): ValidatorFn[] {
		const valArray = [];
		if (
			!this.noRequired &&
			(this.requiredFields.includes(key) ||
				this.requiredFields.length === 0)
		) {
			valArray.push(Validators.required);
		}

		return valArray;
	}
}
