/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './modules/auth/auth.guard';
import { permissions } from './modules/auth/auth.service';

const routes: Routes = [
	{
		path: 'requests',
		canLoad: [AuthGuard],
		canActivate: [AuthGuard],
		data: { features: [permissions.REQUESTS] },
		loadChildren: () =>
			import('./pages/requests/requests.module').then(
				(m) => m.RequestsModule,
			),
	},
	{
		path: 'lab-requests',
		canLoad: [AuthGuard],
		canActivate: [AuthGuard],
		data: { features: [permissions.LAB_REQUESTS] },
		loadChildren: () =>
			import('./pages/lab-requests/lab-requests.module').then(
				(m) => m.LabRequestsModule,
			),
	},
	{
		path: 'samples',
		canLoad: [AuthGuard],
		canActivate: [AuthGuard],
		data: { features: [permissions.SAMPLES] },
		loadChildren: () =>
			import('./pages/samples/samples.module').then(
				(m) => m.SamplesModule,
			),
	},
	{
		path: 'management',
		canLoad: [AuthGuard],
		canActivate: [AuthGuard],
		data: {
			features: [
				permissions.USERS,
				permissions.LABS,
				permissions.AGREEMENT_FORM,
				permissions.ACCESS_LOGS,
			],
		},
		loadChildren: () =>
			import('./pages/management/management.module').then(
				(m) => m.ManagementModule,
			),
	},
	{
		path: 'my-lab',
		canLoad: [AuthGuard],
		canActivate: [AuthGuard],
		data: { features: [permissions.OWN_LAB] },
		loadChildren: () =>
			import('./pages/my-lab/my-lab.module').then((m) => m.MyLabModule),
	},
	{
		path: 'hub-labs',
		canLoad: [AuthGuard],
		canActivate: [AuthGuard],
		data: { features: [permissions.HUB_LABS] },
		loadChildren: () =>
			import('./pages/hub-labs/hub-labs.module').then(
				(m) => m.HubLabsModule,
			),
	},
	{
		path: 'user',
		canLoad: [AuthGuard],
		loadChildren: () =>
			import('./pages/user/user.module').then((m) => m.UserModule),
	},
	{
		path: '',
		loadChildren: () =>
			import('./pages/default/default.module').then(
				(m) => m.DefaultModule,
			),
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
