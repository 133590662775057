/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PathologyRepresentation } from '../models/pathology-representation';

@Injectable({
	providedIn: 'root',
})
export class PathologyControllerService extends BaseService {
	constructor(
		config: ApiConfiguration,
		http: HttpClient
	) {
		super(config, http);
	}

  /**
   * Path part for operation updatePathology
   */
  static readonly UpdatePathologyPath = '/api/labrequests/{id}/pathology/{pathologyId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePathology()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePathology$Response(params: {
    id: number;
    pathologyId: number;
    body: PathologyRepresentation
  }): Observable<StrictHttpResponse<PathologyRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, PathologyControllerService.UpdatePathologyPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('pathologyId', params.pathologyId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PathologyRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updatePathology$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePathology(params: {
    id: number;
    pathologyId: number;
    body: PathologyRepresentation
  }): Observable<PathologyRepresentation> {

    return this.updatePathology$Response(params).pipe(
      map((r: StrictHttpResponse<PathologyRepresentation>) => r.body as PathologyRepresentation)
    );
  }

  /**
   * Path part for operation removePathology
   */
  static readonly RemovePathologyPath = '/api/labrequests/{id}/pathology/{pathologyId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removePathology()` instead.
   *
   * This method doesn't expect any request body.
   */
  removePathology$Response(params: {
    id: number;
    pathologyId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PathologyControllerService.RemovePathologyPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('pathologyId', params.pathologyId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removePathology$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removePathology(params: {
    id: number;
    pathologyId: number;
  }): Observable<void> {

    return this.removePathology$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation addPathology
   */
  static readonly AddPathologyPath = '/api/labrequests/{id}/pathology';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addPathology()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addPathology$Response(params: {
    id: number;
    body: PathologyRepresentation
  }): Observable<StrictHttpResponse<PathologyRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, PathologyControllerService.AddPathologyPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PathologyRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addPathology$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addPathology(params: {
    id: number;
    body: PathologyRepresentation
  }): Observable<PathologyRepresentation> {

    return this.addPathology$Response(params).pipe(
      map((r: StrictHttpResponse<PathologyRepresentation>) => r.body as PathologyRepresentation)
    );
  }

}
