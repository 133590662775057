<!--
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
-->
<div class="labtech-display">
	<ng-container *ngIf="labTech?.paBlock">
		<ng-container *ngFor="let key of labTechKeys">
			<ng-container *ngIf="labTech.paBlock[key].selected">
				<div class="labtech-line">
					<div class="labtech-key">{{ key | translate }}</div>
					<div class="labtech-options">
						<ng-container *ngIf="labTech.paBlock[key].selected">
							<div
								class="labtech-suboption labtech-suboption-block"
								*ngFor="
									let option of labTech.paBlock[key].options
								"
							>
								<div
									*ngIf="option?.label"
									class="labtech-label labtech-label-block labtech-label-suboption"
								>
									{{ option.label | translate }}
								</div>
								<div class="labtech-value">
									{{ option.value }}
								</div>
							</div>
						</ng-container>
					</div>
				</div>
			</ng-container>
		</ng-container>
	</ng-container>
</div>
