<!--
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
-->
<div
	ngbDropdown
	class="d-flex h-100"
	placement="bottom-right"
>
	<button
		class="btn btn-link text-base-color m-auto"
		id="languageDropdown"
		ngbDropdownToggle
	>
		<img src="/assets/images/flag_{{ currentLanguage }}.png" />
	</button>
	<div
		ngbDropdownMenu
		aria-labelledby="languageDropdown"
	>
		<button
			ngbDropdownItem
			*ngFor="let language of languages"
			(click)="useLanguage(language.code)"
		>
			<img src="/assets/images/flag_{{ language.code }}.png" />
			{{ language.translationTag | translate }} ({{ language.code }})
		</button>
	</div>
</div>
