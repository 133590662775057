/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
import { Component, Input, OnInit } from '@angular/core';
import { LabTechPa } from 'src/app/modules/shared/interfaces/lab-tech.type';

export interface PaOtherDisplay {
	paOther: LabTechPa[];
}

@Component({
	selector: 'palga-pa-other-display',
	templateUrl: './pa-other-display.component.html',
	styleUrls: ['./pa-other-display.component.scss'],
})
export class PaOtherDisplayComponent implements OnInit {
	@Input() paOther: PaOtherDisplay;

	paOtherKeys: string[];

	constructor() {}

	ngOnInit(): void {
		if (this.paOther?.paOther) {
			this.paOtherKeys = Object.keys(this.paOther.paOther);
		}
	}
}
