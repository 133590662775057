<!--
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
-->
<div>
	<!-- If in 'Approval' status and user type 'palga' or 'scientific_council' or 'lab_user' -->
	<h4 class="h4 palga-form-section-header">
		{{ 'Review by the scientific committee' | translate }}
	</h4>

	<span *ngIf="request.skipStatusApproval">
		{{
			'The scientific committee and privacy committee of Palga have not been involved in this request.'
				| translate
		}}
	</span>
	<span
		*ngIf="
			!isPalga() && !isScientificCouncil() && !request.skipStatusApproval
		"
	>
		{{
			'The scientific council and privacy committee of Palga have positively reviewed the request.'
				| translate
		}}
	</span>

	<ng-container *ngIf="isPalga() || isScientificCouncil()">
		<a
			href="mailto:{{ scientificCouncilEmail }}
				?subject=Request {{ request.requestNumber }}&amp;
				body=Request {{ request.requestNumber }}
				%0AView: {{ serverUrl }}
        "
			*ngIf="
				isPalga() &&
				request.status == 'Approval' &&
				scientificCouncilEmail
			"
		>
			<button
				class="btn btn-outline-dark"
				type="button"
				title="{{ 'Compose mail to members' | translate }}"
			>
				<span><i class="bi bi-envelope"></i></span>
				{{ 'Compose mail to members' | translate }}
			</button>
		</a>
		<br />

		<h4>
			{{ 'Review_short' | translate }}
			<span class="badge badge-blue rounded-circle">
				{{ size(request.approvalVotes) }}
			</span>
		</h4>

		<span
			class="print-only"
			*ngIf="!request.approvalVotes || request.approvalVotes == null"
		>
			<em>{{ 'None' | translate }}</em>
		</span>

		<!-- Status -->
		<div class="row">
			<div class="col-sm-8">
				<div
					class="panel panel-default"
					*ngFor="let vote of request.approvalVotes | keyvalue"
				>
					<div class="vote-row">
						<span *ngIf="vote.value.value == 'ACCEPTED'">
							<i class="bi-check-circle"></i>
						</span>
						<span *ngIf="vote.value.value == 'REJECTED'"
							><i class="bi bi-x-circle"></i>
						</span>
						<span *ngIf="vote.value.value == 'NONE'">
							<i class="bi bi-question-circle"></i>
						</span>
						{{ vote.value.value | translate }} &nbsp;
						<small *ngIf="getName(vote.value.creator)">
							({{ getName(vote.value.creator) }})
						</small>
					</div>
				</div>
			</div>
		</div>

		<!-- Add vote -->
		<div
			*ngIf="isScientificCouncil() && request.status == 'Approval'"
			class="row no-print"
		>
			<div class="col-sm-12">
				<button
					class="btn btn-outline-dark spaced-button {{
						request.approvalVotes[authService.currentUserValue.id]
							?.value == 'ACCEPTED'
							? 'active'
							: ''
					}}"
					type="button"
					title="{{ 'Approve' | translate }}"
					id="vote-accepted"
					(click)="updateVote(request, 'ACCEPTED')"
					*ngIf="!editComment?.id"
					[disabled]="dataLoading"
				>
					<span><i class="bi bi-check-circle"></i></span>
					{{ 'Approve' | translate }}
				</button>
				<button
					class="btn btn-outline-dark spaced-button {{
						request.approvalVotes[authService.currentUserValue.id]
							?.value == 'REJECTED'
							? 'active'
							: ''
					}}"
					type="button"
					title="{{ 'Reject' | translate }}"
					id="vote-rejected"
					(click)="updateVote(request, 'REJECTED')"
					*ngIf="!editComment?.id"
					[disabled]="dataLoading"
				>
					<span><i class="bi bi-x-circle"></i></span>
					{{ 'Reject' | translate }}
				</button>
			</div>
		</div>

		<h4>{{ 'Discussion' | translate }}</h4>

		<palga-comments
			[id]="request.processInstanceId"
			[comments]="request.approvalComments"
			[commentType]="commentType"
			[commentEditVisibility]="commentEditVisibility"
			[allowAdd]="request.status == 'Approval'"
		>
		</palga-comments>
	</ng-container>
</div>
