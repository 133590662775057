<!--
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
-->
<div class="sample-list">
	<ul class="sample-list-list">
		<li
			*ngFor="let sample of samples; let i = index"
			class="sample-list-item"
		>
			<span
				*ngIf="sample !== 'BLANCOCOUPES'"
				class="sample-list-item-cross"
				(click)="removeSample(i)"
				>×</span
			>
			<span
				*ngIf="sample !== 'BLANCOCOUPES'"
				class="sample-list-item-text"
				>{{ sample }}</span
			>
		</li>
	</ul>
	<div class="mb-3 sample-list-mb-3">
		<input
			#sampleInput
			type="text"
			class="form-control sample-list-input"
			autofocus
			[disabled]="disabled"
			[placeholder]="placeholder"
			(keyup)="handleComma($event)"
			(change)="addSample($event)"
		/>
		<div class="sample-list-counter">{{ countSamples() }}</div>
	</div>
</div>
