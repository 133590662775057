/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FileValidators } from 'ngx-file-drag-drop';

@Component({
	selector: 'palga-upload',
	templateUrl: './upload.component.html',
	styleUrls: ['./upload.component.scss'],
})
export class UploadComponent implements OnInit {
	// mime types which can be uploaded
	@Input() allowedMimeTypes: string;

	// emit files when clicking Upload button
	@Output() fileUpload = new EventEmitter<File[]>();

	// if it's allowed to upload multiple files
	@Input() canUploadMultiple: boolean = true;

	//max file size 20 MB, needs to be the same as spring.servlet.multipart.max-file-size in the backend .properties file
	maxFilesize = 20971520;

	// Validators not working at the moment
	fileControl = new UntypedFormControl([], [FileValidators.uniqueFileNames]);
	// show file size
	@Input() displayFileSize: boolean = true;

	errors = [];

	isUploading: boolean;

	constructor(private translateService: TranslateService) {}

	ngOnInit(): void {
		// Validate file size
		this.fileControl.valueChanges.subscribe((files: File[]) => {
			this.errors = [];
			files.forEach((file) => {
				if (file.size >= this.maxFilesize) {
					this.errors.push(
						this.translateService.instant(
							'Uploading filename not allowed, file larger then max filesize: maxFileSize MB',
							{
								filename: file.name,
								maxFileSize: Math.floor(
									this.maxFilesize * 0.000001,
								),
							},
						),
					);
					this.stopShowingUpload();
				}
			});
		});
	}

	/**
	 * Emit the files to parent component which uploads to the correct endpoint
	 */
	uploadFiles() {
		this.isUploading = true;
		if (this.fileControl.value.length > 0 && this.errors.length === 0) {
			this.fileUpload.emit(this.fileControl.value);

			// clear the files
			this.fileControl.setValue([]);
		}
	}

	/**
	 * Stops showing the spinner. To be called from parent
	 */
	public stopShowingUpload() {
		this.isUploading = false;
	}
}
