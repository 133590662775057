/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import {
	NgbAlertModule,
	NgbDropdownModule,
	NgbModule,
	NgbPaginationModule,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from './components/button/button.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LanguageService } from './services/language.service';
import { TablePaginationComponent } from './components/table-pagination/table-pagination.component';
import { SortableTableHeaderDirective } from './directives/sortable-table-header.directive';
import { RouterModule } from '@angular/router';
import { MenuComponent } from './components/menu/menu.component';
import { MenuUserComponent } from './components/menu-user/menu-user.component';
import { LanguageSelectComponent } from './components/language-select/language-select.component';
import { SidebarSharedComponent } from './components/sidebar/sidebar-shared.component';
import { StatusModalComponent } from './components/modals/status-modal/status-modal.component';
import { ConfirmComponent } from './components/modals/confirm/confirm.component';
import { DiscardComponent } from './components/modals/discard/discard.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { UploadComponent } from './components/upload/upload.component';
import { NgxFileDragDropModule } from 'ngx-file-drag-drop';
import { CommentsComponent } from './components/comments/comments.component';
import { ConfirmWithTextInputComponent } from './components/modals/confirm-with-text-input/confirm-with-text-input.component';
import { TrimDirective } from './directives/trim.directive';
import { LogPipe } from './pipes/log.pipe';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { RoleSelectionModalComponent } from './components/role-selection-modal/role-selection-modal.component';
import { LabListComponent } from './components/lab-list/lab-list.component';
import { LabContactFormComponent } from './components/lab-contact-form/lab-contact-form.component';
import { LabTechniqueSelectorComponent } from './components/lab-technique-selector/lab-technique-selector.component';
import { LabTechniquePASelectorComponent } from './components/lab-technique-pa-selector/lab-technique-pa-selector.component';
import { DntpYellComponent } from './components/dntp-yell/dntp-yell.component';
import { SampleListComponent } from './components/sample-list/sample-list.component';
import { LabTechDisplayComponent } from './components/lab-tech-display/lab-tech-display.component';
import { PaOtherDisplayComponent } from './components/pa-other-display/pa-other-display.component';
import { ApprovalsComponent } from './components/approvals/approvals.component';
import { ContactDataAddressComponent } from './components/contact-data-address/contact-data-address.component';

@NgModule({
	declarations: [
		HeaderComponent,
		FooterComponent,
		ButtonComponent,
		TablePaginationComponent,
		SortableTableHeaderDirective,
		MenuComponent,
		MenuUserComponent,
		LanguageSelectComponent,
		SidebarSharedComponent,
		StatusModalComponent,
		SidebarSharedComponent,
		ConfirmComponent,
		DiscardComponent,
		UploadComponent,
		CommentsComponent,
		ConfirmWithTextInputComponent,
		TrimDirective,
		LogPipe,
		RoleSelectionModalComponent,
		LabListComponent,
		LabContactFormComponent,
		LabTechniqueSelectorComponent,
		LabTechniquePASelectorComponent,
		DntpYellComponent,
		SampleListComponent,
		LabTechDisplayComponent,
		PaOtherDisplayComponent,
		ApprovalsComponent,
		ContactDataAddressComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		RouterModule,
		NgbModule,
		NgbDropdownModule,
		NgbPaginationModule,
		NgbAlertModule,
		NgxFileDragDropModule,
		MatChipsModule,
		MatIconModule,
		MatCheckboxModule,
		MatAutocompleteModule,
	],
	providers: [LanguageService],
	exports: [
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		NgbModule,
		NgbDropdownModule,
		NgbPaginationModule,
		NgbAlertModule,
		MatChipsModule,
		MatIconModule,
		HeaderComponent,
		FooterComponent,
		ButtonComponent,
		TablePaginationComponent,
		SortableTableHeaderDirective,
		SidebarSharedComponent,
		UploadComponent,
		CommentsComponent,
		TrimDirective,
		LogPipe,
		MatCheckboxModule,
		MatAutocompleteModule,
		LabListComponent,
		LabContactFormComponent,
		LabTechniqueSelectorComponent,
		LabTechniquePASelectorComponent,
		DntpYellComponent,
		SampleListComponent,
		LabTechDisplayComponent,
		PaOtherDisplayComponent,
		ApprovalsComponent,
		ContactDataAddressComponent,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
