/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Route, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { AuthService } from 'src/app/modules/auth/auth.service';
import { ProfileRepresentation } from 'src/app/modules/generated/models';

@Component({
	selector: 'palga-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
	// whether the user is logged in (enables menu(s))
	userLoggedIn: boolean;

	// UserInfo used for showing username/role
	currentUser: ProfileRepresentation = {};

	public showDntpTheme: boolean;

	constructor(
		private authService: AuthService,
		private route: Router,
	) {
		this.authService.currentUser.subscribe((user) => {
			this.currentUser = user;
			this.userLoggedIn = user !== null;
		});

		this.route.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				// Have to use any here due to bad typing of Angular
				map((event: any) => event.url),
			)
			.subscribe((url) => {
				this.showDntpTheme = url.includes('lab-request');
				// also show dntp theme for lab and hub users
				if (
					this.authService.hasRole('lab_user') ||
					this.authService.hasRole('hub_user')
				) {
					this.showDntpTheme = true;
				}
			});
	}

	ngOnInit(): void {}
}
