/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'palga-table-pagination',
	templateUrl: './table-pagination.component.html',
	styleUrls: ['./table-pagination.component.scss'],
})
export class TablePaginationComponent implements OnInit {
	@Input() dataSource: any;
	@Input() dataLength: number;

	constructor() {}

	ngOnInit(): void {}
}
