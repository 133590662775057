/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
import { Component, Input, OnInit } from '@angular/core';
import {
	ApprovalVoteRepresentation,
	CommentRepresentation,
	ProfileRepresentation,
	RequestRepresentation,
} from 'src/app/modules/generated/models';
import { AuthService } from 'src/app/modules/auth/auth.service';
import {
	ApprovalVoteControllerService,
	CommentControllerService,
	UserControllerService,
} from 'src/app/modules/generated/services';
import { CommentType } from 'src/app/modules/shared/services/comments.service';

@Component({
	selector: 'palga-approvals',
	templateUrl: './approvals.component.html',
	styleUrls: ['./approvals.component.scss'],
})
export class ApprovalsComponent implements OnInit {
	@Input() request: RequestRepresentation;
	@Input() editComment: any;
	@Input() approvalComment: CommentRepresentation;
	@Input() commentEditVisibility: object;
	dataLoading: boolean;
	error: string;
	scientificCouncilEmail: string;
	serverUrl: string;
	commentType: CommentType = CommentType.APPROVAL;

	constructor(
		public authService: AuthService,
		private userService: UserControllerService,
		private voteControllerService: ApprovalVoteControllerService,
		private commentControllerService: CommentControllerService,
	) {}

	ngOnInit(): void {
		this.serverUrl = location.href;
		this.isLabUser();
		if (this.isPalga()) {
			this.userService
				.getScientificCouncilMembers()
				.subscribe((response) => {
					this.scientificCouncilEmail = '';
					for (let i = 0; i < response.length; i++) {
						if (response[i].contactData) {
							if (this.scientificCouncilEmail.length > 0) {
								this.scientificCouncilEmail += '; ';
							}
							var name = this.getName(response[i]).trim();
							if (name.length > 0) {
								this.scientificCouncilEmail +=
									name +
									' <' +
									response[i].contactData.email +
									'>';
							} else {
								this.scientificCouncilEmail +=
									response[i].contactData.email;
							}
						}
					}
				});
		}
	}

	/**
	 * Returns true if current user role is palga user.
	 */
	isPalga(): boolean {
		return this.authService.hasRole('palga');
	}

	/**
	 * Returns true if current user role is Science council.
	 */
	isScientificCouncil(): boolean {
		return this.authService.hasRole('scientific_council');
	}

	/**
	 * Returns true if current user role is lab user.
	 */
	isLabUser(): boolean {
		return this.authService.hasRole('lab_user');
	}

	/**
	 * Check if the userId argument is the same as the current logged in user ID
	 */
	isCurrentUser(userId: number) {
		return userId == this.authService.currentUserValue.id;
	}

	getName(user: ProfileRepresentation): string {
		if (user === null) {
			return '';
		}
		return (user.firstName + ' ' + user.lastName).trim();
	}

	size(obj) {
		var size = 0,
			key;
		for (key in obj) {
			if (Object.prototype.hasOwnProperty.call(obj, key)) {
				size++;
			}
		}
		return size;
	}

	updateVote(request, value) {
		if (!this.dataLoading) {
			this.dataLoading = true;

			this.voteControllerService
				.addVote({
					id: request.processInstanceId,
					body: {
						value: value,
						processInstanceId: request.processInstanceId,
					},
				})
				.subscribe(
					(result) => {
						this.request.approvalVotes[
							this.authService.currentUserValue.id
						] = result;
						this.dataLoading = false;
					},
					(response) => {
						this.error = this.error + response.data.message + '\n';
						this.dataLoading = false;
					},
				);
		}
	}

	addApprovalComment(request, body) {
		this.dataLoading = true;

		this.commentControllerService
			.addApprovalComment({ id: request.processInstanceId, body: body })
			.subscribe(
				(result: CommentRepresentation) => {
					this.request.approvalComments.push(result);
					this.approvalComment = {};
					this.dataLoading = false;
				},
				(response) => {
					this.error = response.statusText;
					this.dataLoading = false;
				},
			);
	}

	updateApprovalComment(request, body: ApprovalVoteRepresentation) {
		this.dataLoading = true;

		this.voteControllerService
			.updateVote({
				id: request.processInstanceId,
				voteId: body.id,
				body: body,
			})
			.subscribe(
				(result) => {
					var index = this.request.approvalComments.indexOf(body);
					this.request.approvalComments[index] = result;
					this.commentEditVisibility[body.id] = 0;
					this.dataLoading = false;
				},
				(response) => {
					this.error = this.error + response.data.message + '\n';
					this.dataLoading = false;
				},
			);
	}

	removeApprovalComment(comment: CommentRepresentation) {
		this.dataLoading = true;

		this.commentControllerService
			.removeApprovalComment({
				id: this.request.processInstanceId,
				commentId: comment.id,
			})
			.subscribe(
				() => {
					this.request.approvalComments.splice(
						this.request.approvalComments.indexOf(comment),
						1,
					);
					this.dataLoading = false;
				},
				(response) => {
					this.error = this.error + response.data.message + '\n';
					this.dataLoading = false;
				},
			);
	}
}
