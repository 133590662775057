/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { LabRepresentation } from '../models/lab-representation';

@Injectable({
	providedIn: 'root',
})
export class LabProfileControllerService extends BaseService {
	constructor(
		config: ApiConfiguration,
		http: HttpClient
	) {
		super(config, http);
	}

  /**
   * Path part for operation getList
   */
  static readonly GetListPath = '/api/public/labs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getList$Response(params?: {
  }): Observable<StrictHttpResponse<Array<LabRepresentation>>> {

    const rb = new RequestBuilder(this.rootUrl, LabProfileControllerService.GetListPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LabRepresentation>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getList(params?: {
  }): Observable<Array<LabRepresentation>> {

    return this.getList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LabRepresentation>>) => r.body as Array<LabRepresentation>)
    );
  }

  /**
   * Path part for operation get
   */
  static readonly GetPath = '/api/public/labs/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `get()` instead.
   *
   * This method doesn't expect any request body.
   */
  get$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<LabRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, LabProfileControllerService.GetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `get$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  get(params: {
    id: number;
  }): Observable<LabRepresentation> {

    return this.get$Response(params).pipe(
      map((r: StrictHttpResponse<LabRepresentation>) => r.body as LabRepresentation)
    );
  }

}
