<!--
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
-->
<div class="lab-list w-100">
	<select
		class="form-control w-100"
		[size]="selectSize"
		name="lab-selector"
		[value]="selectedLab?.id"
		[disabled]="disable"
		[required]="isRequired"
		(change)="emitChange(labSelector.value)"
		#labSelector
	>
		<option
			*ngFor="let lab of labs$ | async"
			[value]="lab.id"
			[selected]="lab.id === selectedLab?.id"
		>
			{{ lab.name }}
		</option>
	</select>
</div>
