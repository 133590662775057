<!--
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
-->
<div class="navbar-collapse navbar-expand h-100 bg-header-light">
	<ul class="nav navbar-nav list-group h-100">
		<li
			class="nav-item"
			[routerLinkActive]="['active']"
			*ngIf="canViewRequests()"
		>
			<a
				class="nav-link d-flex align-items-center h-100 text-base-color"
				[routerLink]="['/requests']"
			>
				{{ 'Requests' | translate }}
			</a>
		</li>
		<li
			class="nav-item"
			[routerLinkActive]="['active']"
			*ngIf="canViewLabRequests()"
		>
			<a
				class="nav-link d-flex align-items-center h-100 text-base-color"
				[routerLink]="['/lab-requests/list']"
			>
				{{ 'Lab requests' | translate }}
			</a>
		</li>
		<li
			class="nav-item"
			[routerLinkActive]="['active']"
			*ngIf="canViewSamples()"
		>
			<a
				class="nav-link d-flex align-items-center h-100 text-base-color"
				[routerLink]="['/samples']"
			>
				{{ 'Samples' | translate }}
			</a>
		</li>
		<li
			class="nav-item"
			[routerLink]="['/lab-requests/my-lab']"
			[routerLinkActive]="['active']"
			*ngIf="isLabUser()"
		>
			<a class="nav-link d-flex align-items-center h-100 text-base-color">
				{{ 'Requests from my lab' | translate }}
			</a>
		</li>
		<li
			class="nav-item"
			[routerLinkActive]="['active']"
			*ngIf="canViewManagement()"
		>
			<div
				ngbDropdown
				class="d-flex align-items-center h-100 text-base-color"
			>
				<a
					ngbDropdownToggle
					class="nav-link d-flex align-items-center h-100 text-base-color"
					id="menuManagement"
				>
					{{ 'Management' | translate }}
				</a>

				<div
					ngbDropdownMenu
					aria-labelledby="menuManagement"
					class="dropdown-menu dropdown-menu-right"
				>
					<a
						ngbDropdownItem
						[routerLink]="['/management/users']"
						*ngIf="canViewUsers()"
					>
						{{ 'User management' | translate }}
					</a>
					<a
						ngbDropdownItem
						[routerLink]="['/management/labs']"
						*ngIf="canViewLabs()"
					>
						{{ 'Laboratory management' | translate }}
					</a>
					<a
						ngbDropdownItem
						(click)="downloadExportRequests()"
					>
						{{ 'Export requests' | translate }}
					</a>
					<a
						ngbDropdownItem
						[routerLink]="['/management/agreement-form']"
						*ngIf="canEditAgreementFormTemplate()"
					>
						{{ 'Edit form' | translate }}
					</a>
					<a
						ngbDropdownItem
						[routerLink]="['/management/access-logs']"
						*ngIf="canViewAccessLogs()"
					>
						{{ 'Access logs' | translate }}
					</a>
				</div>
			</div>
		</li>
		<li
			class="nav-item"
			[routerLinkActive]="['active']"
			*ngIf="canViewOwnLab()"
		>
			<a
				class="nav-link d-flex align-items-center h-100 text-base-color"
				[routerLink]="['/my-lab']"
			>
				{{ 'My lab' | translate }}
			</a>
		</li>
		<li
			class="nav-item"
			[routerLinkActive]="['active']"
			*ngIf="canViewHubLabs()"
		>
			<a
				class="nav-link d-flex align-items-center h-100 text-base-color"
				[routerLink]="['/hub-labs']"
			>
				{{ 'Hub labs' | translate }}
			</a>
		</li>
	</ul>
</div>
