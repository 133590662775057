/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'palga-dntp-yell',
	templateUrl: './dntp-yell.component.html',
	styleUrls: ['./dntp-yell.component.scss'],
})
export class DntpYellComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
