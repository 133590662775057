/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import {
	ProfileRepresentation,
	RequestRepresentation,
} from '../generated/models';

/**
 * Validator used for comparing the old/new password field values to see if they match
 */
export function MatchValidator(
	controlName: string,
	matchingControlName: string,
) {
	return (formGroup: UntypedFormGroup) => {
		const control = formGroup.controls[controlName];
		const matchingControl = formGroup.controls[matchingControlName];

		if (
			matchingControl.errors &&
			!matchingControl.errors.confirmedValidator
		) {
			return;
		}

		if (control.value !== matchingControl.value) {
			matchingControl.setErrors({ confirmedValidator: true });
		} else {
			matchingControl.setErrors(null);
		}
	};
}

/**
 * Add leading zero for dates
 */
export function zeroFill(i) {
	return (i < 10 ? '0' : '') + i;
}

export function downloadFile(
	fileData: any,
	fileName: string,
	mimeType: string = 'text/csv',
	extension: string = 'csv',
) {
	download(fileData, fileName, mimeType, extension);
}

export function download(
	data: any,
	filename: string,
	type: string,
	extension: string,
) {
	var localblob = new Blob([data], { type: type });
	let downloadLink = document.createElement('a');
	let url = window.URL.createObjectURL(localblob);
	let isSafariBrowser =
		navigator.userAgent.indexOf('Safari') != -1 &&
		navigator.userAgent.indexOf('Chrome') == -1;

	// set link attributes
	if (isSafariBrowser) {
		downloadLink.setAttribute('target', '_blank');
	}
	downloadLink.setAttribute('href', url);

	let val = filename;
	if (extension.length > 0) {
		val += '.' + extension;
	}

	downloadLink.setAttribute('download', val);
	downloadLink.style.visibility = 'hidden';
	document.body.appendChild(downloadLink);

	// trigger download
	downloadLink.click();

	// remove link
	document.body.removeChild(downloadLink);
}

export function getName(user: ProfileRepresentation): string {
	if (user === null) {
		return '';
	}
	return (user.firstName + ' ' + user.lastName).trim();
}

/**
 * Validator to validate password requirements
 */
export function PasswordValidator(
	control: AbstractControl,
): { [key: string]: boolean } | null {
	if (control.value !== undefined && control.value !== null) {
		// validate length
		if (control.value.length < 6) {
			return { length: false };
		}

		// validate special character
		const verySpecialCharsRegex = /(?=.*[^a-zA-Z0-9 ])/;
		if (!verySpecialCharsRegex.test(control.value)) {
			return { specialCharacter: false };
		}

		// validate number character
		const numericalRegex = /(?=.*[0-9])/;
		if (!numericalRegex.test(control.value)) {
			return { number: false };
		}

		// validate alfabet character
		const alphabeticalRegex = /(?=.*[a-zA-Z])/;
		if (!alphabeticalRegex.test(control.value)) {
			return { alphabet: false };
		}
	}
	return null;
}

export function isValidFormField(
	fieldName: string,
	formGroupName: string,
	validatorName: string,
	currentFormGroup: UntypedFormGroup,
) {
	let formField;

	if (!formGroupName) {
		formField = currentFormGroup.get(fieldName);
	} else {
		const formGroup = currentFormGroup.get(formGroupName);
		formField = formGroup.get(fieldName);
	}

	if (!formField) {
		return false;
	}

	if (formField.errors && formField.errors[validatorName]) {
		return false;
	}

	if (
		!formField.dirty &&
		formField.enabled &&
		validatorName == 'required' &&
		formField.value == '' &&
		hasValidator(formField, 'required')
	) {
		return false; // Show "required field" if form field has not been touched yet
	}

	return true;
}

export function hasValidator(formField, validatorName) {
	if (!formField.validator) {
		return false;
	}

	const validatorsList = formField.validator('');

	return validatorsList && !!validatorsList[validatorName];
}

/**
 * The status 'DataDelivery' has a few sub statusses that are based on other conditions.
 * This translates the text to the correct one.
 */
export function getAlternateStatusText(request: any) {
	if (request.status === 'DataDelivery') {
		if (
			(request.statisticsRequest && request.dataAttachmentCount === 0) ||
			(!request.statisticsRequest && !request.dataDelivered)
		) {
			if (!request.linkageWithPersonalData && !request.pickedUp) {
				return 'Approved, waiting for data';
			} else if (
				request.linkageWithPersonalData &&
				!request.cohortDelivered &&
				!request.pickedUp
			) {
				return 'Approved, waiting for cohort';
			} else if (
				request.linkageWithPersonalData &&
				request.cohortDelivered &&
				!request.pickedUp
			) {
				return 'Cohort delivered';
			} else if (request.pickedUp && !request.dataDelivered) {
				return 'Picked up by Palga advisor';
			}
		} else if (
			request.statisticsRequest &&
			request.dataAttachmentCount > 0
		) {
			return 'Data delivered';
		} else if (
			(request.paReportRequest ||
				isMaterialsRequest(request) ||
				request.clinicalDataRequest) &&
			request.dataDelivered
		) {
			return 'Data delivered, select excerpts';
		}
	}
	// Return status in all other cases
	return request.status;
}

/**
 * True if request is a material request.
 */
export function isMaterialsRequest(request: any): boolean {
	return (
		request.blockMaterialsRequest ||
		request.heSliceMaterialsRequest ||
		request.isOtherMaterialsRequest
	);
}
