/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
import { Component, OnInit } from '@angular/core';
import { languages } from '../../constants';
import { LanguageService } from '../../services/language.service';

@Component({
	selector: 'palga-language-select',
	templateUrl: './language-select.component.html',
	styleUrls: ['./language-select.component.scss'],
})
export class LanguageSelectComponent implements OnInit {
	// languages a user can choose
	languages: any[] = languages;

	// current used language
	currentLanguage: string;

	constructor(private languageService: LanguageService) {}

	ngOnInit(): void {
		this.currentLanguage = this.languageService.getLanguage();
	}

	/**
	 * Changes the current language to another language
	 * @param code The language to use
	 */
	useLanguage(code: string) {
		this.languageService.setLanguage(code);
		this.currentLanguage = code;
	}
}
