/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'log',
})
export class LogPipe implements PipeTransform {
	transform<T = any>(value: T): T {
		console.log('%c-------------------', 'color: red;');
		console.log('Value from pipe:', value);
		console.log('%c-------------------', 'color: red;');
		return value;
	}
}
