<!--
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
-->
<div class="modal-header">
	<button
		type="button"
		class="btn-close"
		aria-label="Close"
		(click)="activeModal.dismiss(false)"
	></button>
</div>
<div class="modal-body">
	<div>
		<h3>{{ messageContent }}</h3>
		<h4>{{ textFieldDescription }}</h4>
	</div>
	<div>
		<textarea
			class="form-control confirm-text-area"
			[(ngModel)]="textFieldContent"
		></textarea>
	</div>
</div>
<div class="modal-footer">
	<palga-button
		color="danger"
		text="Cancel"
		(click)="
			activeModal.close({ buttonChoice: false, text: textFieldContent })
		"
	>
	</palga-button>

	<palga-button
		color="primary"
		text="OK"
		(click)="
			activeModal.close({ buttonChoice: true, text: textFieldContent })
		"
	>
	</palga-button>
</div>
