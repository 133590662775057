/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
import { Injectable } from '@angular/core';
import {
	LaboratoryTechniqueChoicesRepresentation,
	RequestRepresentation,
} from '../../generated/models';
import {
	LabTechValues,
	LabTechChoicesDb,
	LabTechSubOption,
	LabTechSet,
	LabTechValues3,
	LabTechOption,
	LabTechSubOptionKey,
	LabTechPa,
	LabTechPAFormValue,
	LAbTechPADbValues,
} from '../interfaces/lab-tech.type';

@Injectable({
	providedIn: 'root',
})
export class LabtechService {
	constructor() {}

	/**
	 * Takes values from lab-technique-selector
	 * and translates them into a shape the db can handle.
	 * Only values of selected techniques are added, non-selected options are omitted
	 * @todo Rewrite to use label as id
	 * @deprecated Use getDbLabTechniques instead
	 */
	translateLabTechniques(techniques: LabTechValues): LabTechChoicesDb {
		const dbTechniques: LaboratoryTechniqueChoicesRepresentation = {};
		const result = {
			other: '',
			dbTechniques,
		};

		if (techniques.IHC.selected) {
			result.dbTechniques.useIHC = true;
			result.dbTechniques.ihctissueNumber =
				techniques.IHC.options[0].value;
			result.dbTechniques.ihcstainings = techniques.IHC.options[1].value;
			result.dbTechniques.ihctissueThickness =
				techniques.IHC.options[2].value;
		}

		if (techniques['DNA/RNA/protein isolation'].selected) {
			result.dbTechniques.useDNARNA = true;
			result.dbTechniques.dnarnatissueNumber =
				techniques['DNA/RNA/protein isolation'].options[0].value;
			result.dbTechniques.dnarnacoresNumber =
				techniques['DNA/RNA/protein isolation'].options[1].value;
			result.dbTechniques.dnarnarollsNumber =
				techniques['DNA/RNA/protein isolation'].options[2].value;
		}

		if (techniques.TMA.selected) {
			result.dbTechniques.useTMA = true;
			result.dbTechniques.tmacoresNumber =
				techniques.TMA.options[0].value;
			result.dbTechniques.tmacoresDiameter =
				techniques.TMA.options[1].value;
		}

		if (techniques['Other'].selected) {
			result.other = techniques['Other'].options[0].value;
		}

		return result;
	}

	getDbLabTechniques(values: LabTechValues): LabTechChoicesDb {
		const dbTechniques: LaboratoryTechniqueChoicesRepresentation = {
			useIHC: false,
			ihctissueThickness: '',
			ihcstainings: '',
			ihctissueNumber: '',
			useDNARNA: false,
			dnarnacoresNumber: '',
			dnarnarollsNumber: '',
			dnarnatissueNumber: '',
			useTMA: false,
			tmacoresNumber: '',
			tmacoresDiameter: '',
		};
		const result = {
			other: '',
			dbTechniques,
		};

		if (values.IHC?.selected) {
			result.dbTechniques.useIHC = true;
			values.IHC.options.forEach((option) => {
				result.dbTechniques = {
					...result.dbTechniques,
					...this.getOptionValues(option),
				};
			});
		}

		if (values.TMA?.selected) {
			result.dbTechniques.useTMA = true;
			values.TMA.options.forEach((option) => {
				result.dbTechniques = {
					...result.dbTechniques,
					...this.getOptionValues(option),
				};
			});
		}

		if (values['DNA/RNA/protein isolation']?.selected) {
			result.dbTechniques.useDNARNA = true;
			values['DNA/RNA/protein isolation'].options.forEach((option) => {
				result.dbTechniques = {
					...result.dbTechniques,
					...this.getOptionValues(option),
				};
			});
		}

		if (values['Other'].selected) {
			result.other = values['Other'].options[0].value;
		}

		return result;
	}

	private getOptionValues(
		option: LabTechOption<LabTechSubOptionKey>,
	): LaboratoryTechniqueChoicesRepresentation {
		return {
			[option.dbKey]: option.value,
		};
	}

	/**
	 * Takes values from lab-technique-selector
	 * and translates them into a shape the db can handle.
	 * Only values of selected techniques are added, non-selected options are omitted
	 * @deprecated For possible future use
	 * @todo Add new sub options from 11-5-2022 meeting
	 */
	translateLabTechniques2(techniques: LabTechValues3): LabTechChoicesDb {
		const dbTechniques: LaboratoryTechniqueChoicesRepresentation = {};
		const result = {
			other: '',
			dbTechniques,
		};
		if (techniques.IHC.selected) {
			result.dbTechniques.useIHC = true;
			result.dbTechniques.ihctissueNumber =
				techniques.IHC.options.ihctissueNumber.value;
			result.dbTechniques.ihcstainings =
				techniques.IHC.options.ihcstainings.value;
		}

		if (techniques['DNA/RNA/protein isolation'].selected) {
			result.dbTechniques.useDNARNA = true;
			result.dbTechniques.dnarnatissueNumber =
				techniques[
					'DNA/RNA/protein isolation'
				].options.dnarnatissueNumber.value;
			result.dbTechniques.dnarnacoresNumber =
				techniques[
					'DNA/RNA/protein isolation'
				].options.dnarnacoresNumber.value;
			result.dbTechniques.dnarnarollsNumber =
				techniques[
					'DNA/RNA/protein isolation'
				].options.dnarnarollsNumber.value;
		}

		if (techniques.TMA.selected) {
			result.dbTechniques.useTMA = true;
			result.dbTechniques.tmacoresNumber =
				techniques.TMA.options.tmacoresNumber.value;
			result.dbTechniques.tmacoresDiameter =
				techniques.TMA.options.tmacoresDiameter.value;
		}

		if (techniques['Other'].selected) {
			// TODO: This wont work
			result.other = techniques['Other'].options['Other'].value;
		}

		return result;
	}

	/**
	 * Takes values from db and turns them into a shape that the
	 * lab-technique-selector can use
	 */
	getLabTechValues(
		setValues: LaboratoryTechniqueChoicesRepresentation,
		anders?: string,
	): LabTechValues {
		// Any value can be set or unset, defaults to false
		const useTMA = !!setValues?.useTMA;
		const useIHC = !!setValues?.useIHC;
		const useDNARNA = !!setValues?.useDNARNA;

		return {
			IHC: {
				selected: useIHC,
				options: useIHC
					? [
							{
								label: 'Number of tissue Sections',
								value: setValues?.ihctissueNumber,
								required: true,
								dbKey: 'ihctissueNumber',
							},
							{
								label: 'IHC stainings that will be performed',
								value: setValues?.ihcstainings,
								required: true,
								dbKey: 'ihcstainings',
							},
							{
								label: 'Thickness of the tissue Sections',
								value: setValues?.ihctissueThickness,
								required: true,
								dbKey: 'ihctissueThickness',
							},
						]
					: null,
			},
			TMA: {
				selected: useTMA,
				options: useTMA
					? [
							{
								label: 'Number of cores',
								value: setValues?.tmacoresNumber,
								required: true,
								dbKey: 'tmacoresNumber',
							},
							{
								label: 'Diameter cores',
								value: setValues?.tmacoresDiameter,
								required: true,
								requiredFor: 'Number of cores',
								dbKey: 'tmacoresDiameter',
							},
						]
					: null,
			},
			'DNA/RNA/protein isolation': {
				selected: useDNARNA,
				minOne: true,
				options: useDNARNA
					? [
							{
								label: 'Number of tissue sections',
								value: setValues?.dnarnatissueNumber,
								dbKey: 'dnarnatissueNumber',
							},
							{
								label: 'Thickness of the tissue sections',
								value: setValues?.dnarnatissueThickness,
								required: true,
								requiredFor: 'Number of tissue sections',
								dbKey: 'dnarnatissueThickness',
							},
							{
								label: 'Number of Cores',
								value: setValues?.dnarnacoresNumber,
								dbKey: 'dnarnacoresNumber',
							},
							{
								label: 'Diameter of the cores',
								value: setValues?.dnarnacoresDiameter,
								required: true,
								requiredFor: 'Number of Cores',
								dbKey: 'dnarnacoresDiameter',
							},
							{
								label: 'Number of rolls in Eppendorftube',
								value: setValues?.dnarnarollsNumber,
								dbKey: 'dnarnarollsNumber',
							},
							{
								label: 'Thickness of rolls (µm)',
								value: setValues?.dnarnarollsThickness,
								required: true,
								requiredFor: 'Number of rolls in Eppendorftube',
								dbKey: 'dnarnarollsThickness',
							},
						]
					: null,
			},
			Other: {
				selected: !!anders,
				options: [
					{
						label: '',
						value: anders,
					},
				],
			},
		};
	}

	/**
	 * Get all options available for laberatory techniques
	 * any options filled will replace the standard options
	 */
	public getFullTechniqueMap(
		techniques?: Partial<LabTechValues>,
	): LabTechValues {
		// An empty map of all tech values
		const fullMap: LabTechValues = {
			IHC: {
				selected: false,
				options: [
					{
						label: 'Number of tissue Sections',
						value: '',
						required: true,
						dbKey: 'ihctissueNumber',
					},
					{
						label: 'IHC stainings that will be performed',
						value: '',
						required: true,
						dbKey: 'ihcstainings',
					},
					{
						label: 'Thickness of the tissue Sections',
						value: '',
						required: true,
						dbKey: 'ihctissueThickness',
					},
				],
			},
			'DNA/RNA/protein isolation': {
				selected: false,
				minOne: true,
				options: [
					{
						label: 'Number of tissue sections',
						value: '',
						dbKey: 'dnarnatissueNumber',
					},
					{
						label: 'Thickness of the tissue sections',
						value: '',
						required: true,
						requiredFor: 'Number of tissue sections',
						dbKey: 'dnarnatissueThickness',
					},
					{
						label: 'Number of Cores',
						value: '',
						dbKey: 'dnarnacoresNumber',
					},
					{
						label: 'Diameter of the cores',
						value: '',
						required: true,
						requiredFor: 'Number of Cores',
						dbKey: 'dnarnacoresDiameter',
					},
					{
						label: 'Number of rolls in Eppendorftube',
						value: '',
						dbKey: 'dnarnarollsNumber',
					},
					{
						label: 'Thickness of rolls (µm)',
						value: '',
						required: true,
						requiredFor: 'Number of rolls in Eppendorftube',
						dbKey: 'dnarnarollsThickness',
					},
				],
			},
			TMA: {
				selected: false,
				options: [
					{
						label: 'Number of cores',
						value: '',
						required: true,
						dbKey: 'tmacoresNumber',
					},
					{
						label: 'Diameter cores',
						value: '',
						requiredFor: 'Number of cores',
						required: true,
						dbKey: 'tmacoresDiameter',
					},
				],
			},
			Other: {
				selected: false,
				options: [{ label: '', value: '', required: true }],
			},
		};

		// Loop over the keys of the incoming techniques (from db)
		for (const technique in techniques) {
			const techValueIncoming: LabTechSubOption = techniques[technique];
			// Check if key is correct
			if (Object.prototype.hasOwnProperty.call(fullMap, technique)) {
				const currentKeyStandardValue: LabTechSubOption =
					fullMap[technique];
				// Loop over standard options to fill any incoming option into the standard map
				const options = techValueIncoming.options
					? currentKeyStandardValue.options.map((standardOption) => {
							// Default to standard value
							let currentOption = { ...standardOption };
							// Loop over the incoming options for this key
							techValueIncoming.options.forEach(
								(incomingOption) => {
									if (
										// One technique doesnt have a dbKey
										technique !== 'Other' &&
										!incomingOption.dbKey
									) {
										throw new Error(
											'Missing dbKey in technique map',
										);
									}
									// Compare dbKeys as an id
									if (
										// This technique only has one option
										technique === 'Other' ||
										incomingOption.dbKey ===
											standardOption.dbKey
									) {
										// If the incoming option has a value, set it in full map
										currentOption = {
											// Merge default options
											...currentOption,
											// Overwrite set values
											value: incomingOption.value,
										};
									}
								},
							);
							return currentOption;
						})
					: currentKeyStandardValue.options;
				// Set the key of the full map to the value of the incoming key
				fullMap[technique] = {
					...fullMap[technique],
					...techValueIncoming,
					selected: techValueIncoming.selected,
					options,
				};
			}
		}
		// All labtech options with set values or default
		return fullMap;
	}

	public getLabTechMap(techniques: LabTechValues): LabTechSet {
		const result = {};
		for (const key in techniques) {
			result[key] = techniques[key].selected;
		}
		return result as LabTechSet;
	}

	public getSelectedValues(
		set: LabTechSet,
		values: LabTechValues,
	): LabTechValues {
		const result = { ...values };
		Object.keys(set).forEach((key) => {
			if (values.hasOwnProperty(key)) {
				result[key].selected = set[key];
			}
		});
		return result as LabTechValues;
	}

	/**
	 * Check if all selected and required options have a value
	 */
	public choicesAreValid(
		set: LabTechSet,
		map: LabTechValues,
		startAs: boolean = true,
	): boolean {
		let isValid = startAs;

		for (const key in set) {
			// The selected key in the deepmap is not set here, so we check the set
			if (map.hasOwnProperty(key) && set[key]) {
				const fullValue: LabTechSubOption = {
					...map[key],
					// When set[key] is true, it is selected
					selected: true,
				};

				isValid = this.subOptionIsValid(fullValue);
				// As soon a one key is invalid, the function should return
				if (!isValid) {
					return false;
				}
			}
		}

		return isValid;
	}

	public subOptionIsValid(subOption: LabTechSubOption): boolean {
		const { selected, minOne, options } = subOption;
		if (!selected) {
			return true;
		}
		if (minOne) {
			// At least one of the options that doesnt have a requiredFor key has to have a value
			const hasOneValue = options.some(
				(option) => !option.requiredFor && !!option.value,
			);
			const topLevelOptionsWithValue = options.reduce((prev, cur) => {
				if (!cur.requiredFor && cur.value) {
					prev.push(cur.label);
				}
				return prev;
			}, []);
			// And when one does, the corresponding requiredFor must have a value
			const subValuesValid = topLevelOptionsWithValue.some(
				(optionWithValue) => {
					const neededValue = options.find(
						(option) => option.requiredFor === optionWithValue,
					);
					return options.find(
						(option) => option.label === neededValue.label,
					)?.value;
				},
			);

			return hasOneValue && subValuesValid;
		}
		return !options.some((option) => {
			if (option.required && !option.value) {
				if (
					option.requiredFor &&
					!options.find(
						(subOption) => subOption.label === option.requiredFor,
					)?.value
				) {
					// Not required if the parent has no value
					return false;
				}
				return true;
			}

			return false;
		});
	}

	/**
	 * Determines if at least one of the options is selected and valid
	 */
	public hasAtLeastOne(set: LabTechSet, map: LabTechValues): boolean {
		const setKeys = Object.keys(set);
		const oneSelected = setKeys.some((key) => !!set[key]);
		const selected: LabTechSubOption[] = setKeys
			.filter((key) => !!set[key])
			.map((key) => map[key]);

		return (
			oneSelected &&
			selected.every((option) => this.subOptionIsValid(option))
		);
	}

	public getOptionByLabel(
		options: LabTechOption<LabTechSubOptionKey>[],
		label: LabTechSubOptionKey,
	): LabTechOption<LabTechSubOptionKey> {
		return options.find((option) => option.label === label);
	}

	public getMapFromValues(values: any): LabTechSet {
		const set: LabTechSet = {
			IHC: false,
			'DNA/RNA/protein isolation': false,
			TMA: false,
			Other: false,
		};

		for (const key in set) {
			if (Object.hasOwnProperty.call(values, key)) {
				set[key] = values[key];
			}
		}

		return set;
	}

	public getLabTechPAValues(
		setValues?: Partial<LabTechPAFormValue>,
	): LabTechPa[] {
		const defaultPATechniques: LabTechPa[] = [
			{
				selected: (setValues?.BlancoCoupes as boolean) || false,
				label: 'BlancoCoupes',
				dbKey: 'useBlankSections',
				options: [
					{
						selected: !!setValues?.TypeGlass || false,
						label: 'TypeGlass',
						dbKey: 'blankSectionGlassSlideType',
						value: (setValues?.TypeGlass as string) || '',
						requiredFor: 'BlancoCoupes',
					},
					{
						selected: !!setValues?.ThicknessCoupes || false,
						label: 'ThicknessCoupes',
						dbKey: 'blankSectionTissueThickness',
						value: (setValues?.ThicknessCoupes as string) || '',
						requiredFor: 'BlancoCoupes',
					},
					{
						selected: !!setValues?.NumberOfCoupes || false,
						label: 'NumberOfCoupes',
						dbKey: 'blankSectionNumber',
						value: (setValues?.NumberOfCoupes as string) || '',
						requiredFor: 'BlancoCoupes',
					},
				],
			},
			{
				selected: (setValues?.FreezeMaterial as boolean) || false,
				label: 'FreezeMaterial',
				dbKey: 'frozenMaterial',
			},
			{
				selected: (setValues?.Cytology as boolean) || false,
				label: 'Cytology',
				dbKey: 'cytology',
			},
			{
				selected: (!!setValues?.OtherMaterial as boolean) || false,
				label: 'OtherMaterial',
				dbKey: null,
				options: [
					{
						selected: !!setValues?.OtherMaterial || false,
						label: ' ',
						dbKey: 'otherMaterialsRequest',
						value: (setValues?.OtherMaterial as string) || '',
						requiredFor: 'OtherMaterial',
					},
				],
			},
		];

		return defaultPATechniques;
	}

	public paMapIsValid(values: LabTechPa[]): boolean {
		// At least one technique should be selected
		if (values.every((techValue) => !techValue.selected)) {
			return false;
		}
		return values.every((techValue) => {
			// Some values have sub options
			if (techValue.selected && techValue.options) {
				// At least one suboption should have a value
				return techValue.options.some((option) => !!option.value);
			}
			// Values without options are all good here
			return true;
		});
	}

	public getFormValuesFromDb(
		dbValues?: LAbTechPADbValues,
	): LabTechPAFormValue {
		const formValues: LabTechPAFormValue = {
			FreezeMaterial: dbValues?.frozenMaterial || false,
			Cytology: dbValues?.cytology || false,
			BlancoCoupes: dbValues?.useBlankSections || false,
			NumberOfCoupes: dbValues?.blankSectionNumber || false,
			ThicknessCoupes: dbValues?.blankSectionTissueThickness || false,
			TypeGlass: dbValues?.blankSectionGlassSlideType || false,
			OtherMaterial: dbValues?.otherMaterialsRequest || false,
			' ': dbValues?.otherMaterialsRequest || false,
		};

		return formValues;
	}

	public getDbValuesFromPAValues(paValues: LabTechPa[]): LAbTechPADbValues {
		const dbValues: LAbTechPADbValues = {};
		paValues.forEach((value) => {
			if (value.options) {
				if (
					value.label === 'OtherMaterial' &&
					value.selected === false
				) {
					dbValues['otherMaterialsRequest'] = '';
				} else {
					value.options.forEach((option) => {
						dbValues[option.dbKey] = option.value;
					});
				}
			}
			dbValues[value.dbKey] = value.selected;
		});

		return dbValues;
	}

	/**
	 * Utility function for ease of use
	 */
	public dbToComponentPA(dbValues?: RequestRepresentation): LabTechPa[] {
		const paDbValues = this.getLabTechDBFromRequest(dbValues);
		const formValues = this.getFormValuesFromDb(paDbValues);

		return this.getLabTechPAValues(formValues);
	}

	public getLabTechDBFromRequest(
		request: RequestRepresentation,
	): LAbTechPADbValues {
		return {
			frozenMaterial: request.frozenMaterial,
			cytology: request.cytology,
			useBlankSections: request.useBlankSections,
			blankSectionNumber: request.blankSectionNumber,
			blankSectionTissueThickness: request.blankSectionTissueThickness,
			blankSectionGlassSlideType: request.blankSectionGlassSlideType,
			otherMaterialsRequest: request.otherMaterialsRequest,
		};
	}
}
