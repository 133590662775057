<!--
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
-->
<!-- TODO: Make the actual form -->
<form
	class="lab-contact-form"
	[formGroup]="labDataForm"
>
	<div class="mb-3">
		<label for=""></label>
		<input
			type="text"
			class="form-control"
		/>
	</div>
</form>
