/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { filter, map } from 'rxjs/operators';
import { AuthService } from 'src/app/modules/auth/auth.service';
import { ProfileRepresentation } from 'src/app/modules/generated/models';
import { storeLastRole } from '../../constants';
import {
	RoleSelectionModalComponent,
	RoleSelectionModalResult,
} from '../role-selection-modal/role-selection-modal.component';

@Component({
	selector: 'palga-menu-user',
	templateUrl: './menu-user.component.html',
	styleUrls: ['./menu-user.component.scss'],
})
export class MenuUserComponent implements OnInit {
	@Input() currentUser: ProfileRepresentation;

	constructor(
		private authService: AuthService,
		private router: Router,
		private modalService: NgbModal,
	) {}

	ngOnInit(): void {}

	/**
	 * Logout the current logged in user
	 */
	logout() {
		this.authService.logout();
		this.router.navigate(['/login']);
	}

	/**
	 * Opens a modal where user can select on of its other roles
	 */
	changeUserRole() {
		const ref = this.modalService.open(RoleSelectionModalComponent);
		ref.closed
			.pipe(
				// Only handle when user selects ok
				filter(
					(result: RoleSelectionModalResult) => result.buttonChoice,
				),
				// The text key holds a user object
				map((result) => result.text),
			)
			.subscribe((user) => {
				localStorage.setItem(storeLastRole, user.currentRole);
				this.authService.setCurrentRole(user.currentRole);
				this.router.navigate(['/']);
			});
	}
}
