<!--
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
-->
<!-- user is logged in -->
<ng-container *ngIf="userLoggedIn">
	<!-- create fixed header, needs to render first -->
	<div
		class="row gx-0 p-0 m-0 fixed-top header-logged-in no-print"
		[ngClass]="{ 'dntp-page': showDntpTheme }"
	>
		<!-- create transparent placeholder.. -->
		<div class="col-2 bg-transparent"></div>
		<!-- create menu bar -->
		<div class="col-10 no-padding header-menu bg-header-light">
			<!-- create nav menu -->
			<palga-menu class="float-start h-100">menu</palga-menu>
			<!-- create language select and user menu -->
			<palga-menu-user
				class="float-end h-100"
				[currentUser]="currentUser"
			>
			</palga-menu-user>
			<palga-language-select class="float-end h-100">
			</palga-language-select>
		</div>
	</div>
</ng-container>

<!-- user is not logged in -->
<ng-container *ngIf="!userLoggedIn">
	<div class="row gx-0 header-not-logged-in">
		<div class="col-2 mx-auto h-100 float-start">
			<a
				class="h-100 w-100 d-block d-flex flex-wrap align-items-center"
				[routerLink]="['/']"
			>
				<img
					src="/assets/images/palga.png"
					class="img-fluid mx-auto d-block"
				/>
			</a>
		</div>
		<div class="col-10">
			<palga-language-select class="float-end"></palga-language-select>
		</div>
	</div>
</ng-container>
