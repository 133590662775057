<!--
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
-->
<!-- Comment display -->
<div>
	<span
		class="print-only"
		*ngIf="!comments || comments.length == 0"
	>
		<em>{{ 'None' | translate }}</em>
	</span>
	<ul class="list-group comments">
		<li
			class="list-group-item"
			*ngFor="let comment of comments"
		>
			<div *ngIf="!commentEditVisibility[comment.id]">
				{{ comment.contents }}
			</div>
			<div *ngIf="commentEditVisibility[comment.id]">
				<textarea
					id="editCommentText"
					class="form-control no-print"
					name="editCommentText"
					placeholder="{{ 'Add ' + placeholder | translate }}"
					[ngModel]="comment.contents"
					(keydown.control.enter)="
						updateComment(comment, editComment.value)
					"
					#editComment
				>
				</textarea>
				<button
					class="btn btn-primary btn-sm no-print"
					type="button"
					title="{{ 'Update ' + placeholder | translate }}"
					(click)="updateComment(comment, editComment.value)"
					[disabled]="dataLoading"
				>
					<span><i class="bi bi-pencil"></i></span>
					{{ 'Update' | translate }}
				</button>
			</div>
			<span
				class="float-end no-print"
				*ngIf="isCurrentUser(comment.creator.id)"
			>
				<span class="btn-group btn-group-sm">
					<button
						class="btn btn-info"
						type="button"
						title="{{ 'Edit' | translate }}"
						(click)="startEditing(comment)"
					>
						<span><i class="bi bi-pencil"></i></span>
					</button>
					<button
						class="btn btn-danger"
						type="button"
						title="{{ 'Delete' | translate }}"
						(click)="removeComment(comment)"
						[disabled]="dataLoading"
					>
						<span><i class="bi bi-x-circle"></i></span>
					</button>
				</span>
			</span>
			<p>
				<small>
					<span><i class="bi bi-person-fill"></i></span>
					<em>{{ getName(comment.creator) }}</em>
					&nbsp;
					<span><i class="bi bi-clock"></i></span>
					<span>{{
						comment.timeCreated | date: 'EEE dd MMMM yyyy HH:mm'
					}}</span>
					<span *ngIf="comment.timeEdited != comment.timeCreated">
						(Last edited:
						{{
							comment.timeEdited | date: 'EEE dd MMMM yyyy HH:mm'
						}}
						)
					</span>
					<span
						*ngIf="comment.notificationSent"
						title="{{ 'Notification sent.' | translate }}"
					>
						<i class="bi bi-check"></i
					></span>
				</small>
			</p>
		</li>
	</ul>
</div>

<!-- Add comment -->
<div
	*ngIf="allowAdd"
	class="no-print mt-3"
>
	<form #f="ngForm">
		<input
			type="hidden"
			ng-model="editComment.processInstanceId"
			value="{{ id }}"
		/>
		<textarea
			class="form-control"
			id="noteText"
			name="commentText"
			placeholder="{{ 'Add ' + placeholder | translate }}"
			ngModel
			(keydown.control.enter)="
				$event.preventDefault();
				saveComment(editComment, f.value.commentText, !!editComment.id)
			"
		>
		</textarea>
	</form>
	<button
		class="btn btn-outline-dark mt-2"
		type="button"
		[disabled]="dataLoading"
		[title]="(editComment.id ? 'Update' : 'Add') + ' note' | translate"
		(click)="
			saveComment(editComment, f.value.commentText, !!editComment.id)
		"
	>
		{{
			(editComment.id ? 'Update' : 'Add') + ' ' + placeholder | translate
		}}
	</button>
	<div
		*ngIf="canSendNotifiction()"
		class="mt-4"
	>
		<input
			type="checkbox"
			class="me-2"
			[(ngModel)]="editComment.notificationRequested"
			placeholder="true"
			id="internal-comment-input"
		/>

		<label
			*ngIf="isLabUser()"
			for="internal-comment-input"
		>
			<i>
				{{
					'Send notification to the requester (CC hub users, if applicable).'
						| translate
				}}
			</i>
		</label>

		<label
			*ngIf="isHubUser() || isPalgaUser()"
			for="internal-comment-input"
		>
			<i> {{ 'Send notification to the requester.' | translate }}</i>
		</label>

		<label
			*ngIf="isRequester()"
			for="internal-comment-input"
		>
			<i>
				{{
					'Send notification to the lab (CC hub users, if applicable).'
						| translate
				}}
			</i>
		</label>
	</div>
	<br />
	<br />
</div>
