/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import {
	filter,
	debounceTime,
	distinctUntilChanged,
	tap,
} from 'rxjs/operators';
import { LabTechPa, LabTechPAFormValue } from '../../interfaces/lab-tech.type';
import { LabtechService } from '../../services/labtech.service';

@Component({
	selector: 'palga-lab-technique-pa-selector',
	templateUrl: './lab-technique-pa-selector.component.html',
	styleUrls: ['./lab-technique-pa-selector.component.scss'],
})
export class LabTechniquePASelectorComponent implements OnInit, AfterViewInit {
	@Input() PATechniques: LabTechPa[];

	@Output() PATechniquesChange = new EventEmitter<LabTechPa[]>();

	@ViewChild('PAForm') PAForm: NgForm;

	public isValid: boolean = true;

	constructor(private labTechService: LabtechService) {}

	ngOnInit(): void {
		if (!this.PATechniques) {
			this.PATechniques = this.labTechService.getLabTechPAValues();
		}
		this.isValid =
			// Default to valid
			!this.PATechniques ||
			this.labTechService.paMapIsValid(this.PATechniques);
	}

	ngAfterViewInit(): void {
		if (this.PAForm) {
			this.PAForm.valueChanges
				.pipe(
					filter((_) => this.PAForm.dirty),
					debounceTime(200),
					distinctUntilChanged(),
					tap((_) => {
						this.isValid = this.labTechService.paMapIsValid(
							this.PATechniques,
						);
					}),
				)
				.subscribe((_) => {
					this.PATechniquesChange.emit(this.PATechniques);
				});
		}
	}
}
