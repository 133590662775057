/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/modules/auth/auth.service';
import { isChristmas } from '../../constants';

@Component({
	selector: 'palga-sidebar-shared',
	templateUrl: './sidebar-shared.component.html',
	styleUrls: ['./sidebar-shared.component.scss'],
})
export class SidebarSharedComponent implements OnInit {
	showDntpTheme: boolean;
	isChristmas: boolean = isChristmas;
	// Temp for show
	toggleLogo = false;

	constructor(
		private router: Router,
		private auth: AuthService,
	) {}

	ngOnInit(): void {
		// if my-lab show dntp logo
		// there is another way string.includes(), but this doesn't work for IE..
		if (
			this.router.url.indexOf('my-lab') !== -1 ||
			this.router.url.indexOf('lab-request') !== -1
		) {
			this.showDntpTheme = true;
		}

		// shared sidebar is also used for 'update profile' page
		if (this.auth.hasRole('lab_user') || this.auth.hasRole('hub_user')) {
			this.showDntpTheme = true;
		}
	}
}
