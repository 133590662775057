/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { LzvDataRepresentation } from '../models/lzv-data-representation';
import { RequestListRepresentation } from '../models/request-list-representation';
import { RequestRepresentation } from '../models/request-representation';
import { TransferRepresentation } from '../models/transfer-representation';

@Injectable({
	providedIn: 'root',
})
export class RequestControllerService extends BaseService {
	constructor(
		config: ApiConfiguration,
		http: HttpClient
	) {
		super(config, http);
	}

  /**
   * Path part for operation getRequestById
   */
  static readonly GetRequestByIdPath = '/api/requests/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRequestById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRequestById$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<RequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, RequestControllerService.GetRequestByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRequestById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRequestById(params: {
    id: string;
  }): Observable<RequestRepresentation> {

    return this.getRequestById$Response(params).pipe(
      map((r: StrictHttpResponse<RequestRepresentation>) => r.body as RequestRepresentation)
    );
  }

  /**
   * Path part for operation update
   */
  static readonly UpdatePath = '/api/requests/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `update()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update$Response(params: {
    id: string;
    body: RequestRepresentation
  }): Observable<StrictHttpResponse<RequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, RequestControllerService.UpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `update$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update(params: {
    id: string;
    body: RequestRepresentation
  }): Observable<RequestRepresentation> {

    return this.update$Response(params).pipe(
      map((r: StrictHttpResponse<RequestRepresentation>) => r.body as RequestRepresentation)
    );
  }

  /**
   * Path part for operation remove
   */
  static readonly RemovePath = '/api/requests/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `remove()` instead.
   *
   * This method doesn't expect any request body.
   */
  remove$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RequestControllerService.RemovePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `remove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  remove(params: {
    id: string;
  }): Observable<void> {

    return this.remove$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation withdraw
   */
  static readonly WithdrawPath = '/api/requests/{id}/withdraw';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `withdraw()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  withdraw$Response(params: {
    id: string;
    body: RequestRepresentation
  }): Observable<StrictHttpResponse<RequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, RequestControllerService.WithdrawPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `withdraw$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  withdraw(params: {
    id: string;
    body: RequestRepresentation
  }): Observable<RequestRepresentation> {

    return this.withdraw$Response(params).pipe(
      map((r: StrictHttpResponse<RequestRepresentation>) => r.body as RequestRepresentation)
    );
  }

  /**
   * Path part for operation unclaim
   */
  static readonly UnclaimPath = '/api/requests/{id}/unclaim';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unclaim()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  unclaim$Response(params: {
    id: string;
    body: RequestRepresentation
  }): Observable<StrictHttpResponse<RequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, RequestControllerService.UnclaimPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `unclaim$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  unclaim(params: {
    id: string;
    body: RequestRepresentation
  }): Observable<RequestRepresentation> {

    return this.unclaim$Response(params).pipe(
      map((r: StrictHttpResponse<RequestRepresentation>) => r.body as RequestRepresentation)
    );
  }

  /**
   * Path part for operation suspend
   */
  static readonly SuspendPath = '/api/requests/{id}/suspend';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `suspend()` instead.
   *
   * This method doesn't expect any request body.
   */
  suspend$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<RequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, RequestControllerService.SuspendPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `suspend$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  suspend(params: {
    id: string;
  }): Observable<RequestRepresentation> {

    return this.suspend$Response(params).pipe(
      map((r: StrictHttpResponse<RequestRepresentation>) => r.body as RequestRepresentation)
    );
  }

  /**
   * Path part for operation submit
   */
  static readonly SubmitPath = '/api/requests/{id}/submit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `submit()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  submit$Response(params: {
    id: string;
    body: RequestRepresentation
  }): Observable<StrictHttpResponse<RequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, RequestControllerService.SubmitPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `submit$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  submit(params: {
    id: string;
    body: RequestRepresentation
  }): Observable<RequestRepresentation> {

    return this.submit$Response(params).pipe(
      map((r: StrictHttpResponse<RequestRepresentation>) => r.body as RequestRepresentation)
    );
  }

  /**
   * Path part for operation submitReview
   */
  static readonly SubmitReviewPath = '/api/requests/{id}/submitReview';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `submitReview()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  submitReview$Response(params: {
    id: string;
    body: RequestRepresentation
  }): Observable<StrictHttpResponse<RequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, RequestControllerService.SubmitReviewPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `submitReview$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  submitReview(params: {
    id: string;
    body: RequestRepresentation
  }): Observable<RequestRepresentation> {

    return this.submitReview$Response(params).pipe(
      map((r: StrictHttpResponse<RequestRepresentation>) => r.body as RequestRepresentation)
    );
  }

  /**
   * Path part for operation resume
   */
  static readonly ResumePath = '/api/requests/{id}/resume';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resume()` instead.
   *
   * This method doesn't expect any request body.
   */
  resume$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<RequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, RequestControllerService.ResumePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `resume$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  resume(params: {
    id: string;
  }): Observable<RequestRepresentation> {

    return this.resume$Response(params).pipe(
      map((r: StrictHttpResponse<RequestRepresentation>) => r.body as RequestRepresentation)
    );
  }

  /**
   * Path part for operation reopen
   */
  static readonly ReopenPath = '/api/requests/{id}/reopen';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reopen()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reopen$Response(params: {
    id: string;
    body: RequestRepresentation
  }): Observable<StrictHttpResponse<RequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, RequestControllerService.ReopenPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `reopen$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reopen(params: {
    id: string;
    body: RequestRepresentation
  }): Observable<RequestRepresentation> {

    return this.reopen$Response(params).pipe(
      map((r: StrictHttpResponse<RequestRepresentation>) => r.body as RequestRepresentation)
    );
  }

  /**
   * Path part for operation reject
   */
  static readonly RejectPath = '/api/requests/{id}/reject';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reject()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reject$Response(params: {
    id: string;
    body: RequestRepresentation
  }): Observable<StrictHttpResponse<RequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, RequestControllerService.RejectPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `reject$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reject(params: {
    id: string;
    body: RequestRepresentation
  }): Observable<RequestRepresentation> {

    return this.reject$Response(params).pipe(
      map((r: StrictHttpResponse<RequestRepresentation>) => r.body as RequestRepresentation)
    );
  }

  /**
   * Path part for operation pickUpRequest
   */
  static readonly PickUpRequestPath = '/api/requests/{id}/pickUpRequest';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pickUpRequest()` instead.
   *
   * This method doesn't expect any request body.
   */
  pickUpRequest$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<RequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, RequestControllerService.PickUpRequestPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `pickUpRequest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pickUpRequest(params: {
    id: string;
  }): Observable<RequestRepresentation> {

    return this.pickUpRequest$Response(params).pipe(
      map((r: StrictHttpResponse<RequestRepresentation>) => r.body as RequestRepresentation)
    );
  }

  /**
   * Path part for operation updateLzvData
   */
  static readonly UpdateLzvDataPath = '/api/requests/{id}/lzvData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateLzvData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateLzvData$Response(params: {
    id: string;
    body: LzvDataRepresentation
  }): Observable<StrictHttpResponse<RequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, RequestControllerService.UpdateLzvDataPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateLzvData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateLzvData(params: {
    id: string;
    body: LzvDataRepresentation
  }): Observable<RequestRepresentation> {

    return this.updateLzvData$Response(params).pipe(
      map((r: StrictHttpResponse<RequestRepresentation>) => r.body as RequestRepresentation)
    );
  }

  /**
   * Path part for operation finalise
   */
  static readonly FinalisePath = '/api/requests/{id}/finalise';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `finalise()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  finalise$Response(params: {
    id: string;
    body: RequestRepresentation
  }): Observable<StrictHttpResponse<RequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, RequestControllerService.FinalisePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `finalise$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  finalise(params: {
    id: string;
    body: RequestRepresentation
  }): Observable<RequestRepresentation> {

    return this.finalise$Response(params).pipe(
      map((r: StrictHttpResponse<RequestRepresentation>) => r.body as RequestRepresentation)
    );
  }

  /**
   * Path part for operation deliverCohort
   */
  static readonly DeliverCohortPath = '/api/requests/{id}/deliverCohort';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deliverCohort()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliverCohort$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<RequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, RequestControllerService.DeliverCohortPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deliverCohort$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliverCohort(params: {
    id: string;
  }): Observable<RequestRepresentation> {

    return this.deliverCohort$Response(params).pipe(
      map((r: StrictHttpResponse<RequestRepresentation>) => r.body as RequestRepresentation)
    );
  }

  /**
   * Path part for operation dataDelivered
   */
  static readonly DataDeliveredPath = '/api/requests/{id}/dataDelivered';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dataDelivered()` instead.
   *
   * This method doesn't expect any request body.
   */
  dataDelivered$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<RequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, RequestControllerService.DataDeliveredPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `dataDelivered$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  dataDelivered(params: {
    id: string;
  }): Observable<RequestRepresentation> {

    return this.dataDelivered$Response(params).pipe(
      map((r: StrictHttpResponse<RequestRepresentation>) => r.body as RequestRepresentation)
    );
  }

  /**
   * Path part for operation close
   */
  static readonly ClosePath = '/api/requests/{id}/close';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `close()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  close$Response(params: {
    id: string;
    body: RequestRepresentation
  }): Observable<StrictHttpResponse<RequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, RequestControllerService.ClosePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `close$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  close(params: {
    id: string;
    body: RequestRepresentation
  }): Observable<RequestRepresentation> {

    return this.close$Response(params).pipe(
      map((r: StrictHttpResponse<RequestRepresentation>) => r.body as RequestRepresentation)
    );
  }

  /**
   * Path part for operation claim
   */
  static readonly ClaimPath = '/api/requests/{id}/claim';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `claim()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  claim$Response(params: {
    id: string;
    body: RequestRepresentation
  }): Observable<StrictHttpResponse<RequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, RequestControllerService.ClaimPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `claim$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  claim(params: {
    id: string;
    body: RequestRepresentation
  }): Observable<RequestRepresentation> {

    return this.claim$Response(params).pipe(
      map((r: StrictHttpResponse<RequestRepresentation>) => r.body as RequestRepresentation)
    );
  }

  /**
   * Path part for operation transfer
   */
  static readonly TransferPath = '/api/requests/transfer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `transfer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  transfer$Response(params: {
    body: TransferRepresentation
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RequestControllerService.TransferPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `transfer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  transfer(params: {
    body: TransferRepresentation
  }): Observable<void> {

    return this.transfer$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation fixRequestNumbers
   */
  static readonly FixRequestNumbersPath = '/api/requestNumbers/fix';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fixRequestNumbers()` instead.
   *
   * This method doesn't expect any request body.
   */
  fixRequestNumbers$Response(params?: {
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RequestControllerService.FixRequestNumbersPath, 'put');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fixRequestNumbers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fixRequestNumbers(params?: {
  }): Observable<void> {

    return this.fixRequestNumbers$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getRequestList
   */
  static readonly GetRequestListPath = '/api/requests';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRequestList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRequestList$Response(params: {
    roleName: string;
  }): Observable<StrictHttpResponse<Array<RequestListRepresentation>>> {

    const rb = new RequestBuilder(this.rootUrl, RequestControllerService.GetRequestListPath, 'get');
    if (params) {
      rb.query('roleName', params.roleName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RequestListRepresentation>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRequestList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRequestList(params: {
    roleName: string;
  }): Observable<Array<RequestListRepresentation>> {

    return this.getRequestList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RequestListRepresentation>>) => r.body as Array<RequestListRepresentation>)
    );
  }

  /**
   * Path part for operation start
   */
  static readonly StartPath = '/api/requests';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `start()` instead.
   *
   * This method doesn't expect any request body.
   */
  start$Response(params?: {
  }): Observable<StrictHttpResponse<RequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, RequestControllerService.StartPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `start$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  start(params?: {
  }): Observable<RequestRepresentation> {

    return this.start$Response(params).pipe(
      map((r: StrictHttpResponse<RequestRepresentation>) => r.body as RequestRepresentation)
    );
  }

  /**
   * Path part for operation fork
   */
  static readonly ForkPath = '/api/requests/{id}/forks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fork()` instead.
   *
   * This method doesn't expect any request body.
   */
  fork$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<RequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, RequestControllerService.ForkPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fork$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fork(params: {
    id: string;
  }): Observable<RequestRepresentation> {

    return this.fork$Response(params).pipe(
      map((r: StrictHttpResponse<RequestRepresentation>) => r.body as RequestRepresentation)
    );
  }

  /**
   * Path part for operation getRequestListFromUser
   */
  static readonly GetRequestListFromUserPath = '/api/requests/user/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRequestListFromUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRequestListFromUser$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<Array<RequestListRepresentation>>> {

    const rb = new RequestBuilder(this.rootUrl, RequestControllerService.GetRequestListFromUserPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RequestListRepresentation>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRequestListFromUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRequestListFromUser(params: {
    id: number;
  }): Observable<Array<RequestListRepresentation>> {

    return this.getRequestListFromUser$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RequestListRepresentation>>) => r.body as Array<RequestListRepresentation>)
    );
  }

  /**
   * Path part for operation getRequestCounts
   */
  static readonly GetRequestCountsPath = '/api/requests/counts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRequestCounts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRequestCounts$Response(params: {
    roleName: string;
  }): Observable<StrictHttpResponse<{
[key: string]: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, RequestControllerService.GetRequestCountsPath, 'get');
    if (params) {
      rb.query('roleName', params.roleName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: number;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRequestCounts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRequestCounts(params: {
    roleName: string;
  }): Observable<{
[key: string]: number;
}> {

    return this.getRequestCounts$Response(params).pipe(
      map((r: StrictHttpResponse<{
[key: string]: number;
}>) => r.body as {
[key: string]: number;
})
    );
  }

}
