<!--
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
-->
<div class="labtech-pa w-100">
	<form #PAForm="ngForm">
		<div
			class="col-form-check ms-2"
			*ngFor="let technique of PATechniques"
		>
			<input
				type="checkbox"
				class="me-2"
				[name]="technique.label"
				[id]="technique.label"
				[(ngModel)]="technique.selected"
			/>
			<label
				class="col-md-11"
				[for]="technique.label"
			>
				{{ technique.label | translate }}
			</label>
			<div
				class="labtrech-pa-sub-option technique-sub ms-4"
				*ngIf="technique.selected && technique.options"
			>
				<div
					class="sub-technique-group"
					*ngFor="let option of technique.options"
				>
					<label
						class="technique-sub-label"
						[for]="option.label"
					>
						{{ option.label | translate }}
					</label>
					<input
						type="text"
						class="form-control"
						[name]="option.label"
						[id]="option.label"
						[(ngModel)]="option.value"
					/>
				</div>
			</div>
		</div>
		<div
			class="mandatory-field"
			*ngIf="!isValid"
		>
			{{ 'Required field' | translate }}
		</div>
	</form>
</div>
