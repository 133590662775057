<!--
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
-->
<div class="paother-display mb-2">
	<ng-container *ngIf="paOther?.paOther">
		<ng-container *ngFor="let paOther of paOther.paOther">
			<div
				class="paother-line"
				*ngIf="paOther.selected"
			>
				<div
					class="paother-key"
					*ngIf="paOther?.label"
				>
					{{ paOther.label | translate }}
				</div>
				<div
					*ngIf="paOther.options"
					class="paother-options paother-options-pa"
				>
					<ng-container *ngFor="let option of paOther.options">
						<div
							class="paother-suboption paother-suboption-pa"
							*ngIf="option.value"
						>
							<div
								*ngIf="option?.label"
								class="paother-label paother-label-pa paother-label-suboption"
							>
								{{ option.label | translate }}
							</div>
							<div class="paother-value">
								{{ option.value }}
							</div>
						</div>
					</ng-container>
				</div>
			</div>
		</ng-container>
	</ng-container>
</div>
