/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import {
	debounceTime,
	distinctUntilChanged,
	filter,
	map,
	tap,
} from 'rxjs/operators';
import {
	LabTechSet,
	LabTechKey,
	LabTechValues,
	LabTechSubOption,
} from '../../interfaces/lab-tech.type';
import { LabtechService } from '../../services/labtech.service';

@Component({
	selector: 'palga-lab-technique-selector',
	templateUrl: './lab-technique-selector.component.html',
	styleUrls: ['./lab-technique-selector.component.scss'],
})
export class LabTechniqueSelectorComponent implements OnInit, AfterViewInit {
	/**
	 * Set lab techniques. All values are optional.
	 * To mark a technique without suboptions as selected, use true or an empty array.
	 * Leaving an available technique out will show it unselected.
	 */
	@Input() labTechniques: LabTechValues;

	/**
	 * If at least one selection should be made
	 */
	@Input() isRequired: boolean;

	/**
	 * Emits all values whenever a lab technique value changes
	 * @todo Check with backend if all values should be emitted or just the set values
	 */
	@Output() labTechniquesChange = new EventEmitter<LabTechValues>();

	/**
	 * Tracking which techniques are selected
	 */
	public labTechMap: LabTechSet;

	/**
	 * The values connected to each technique
	 */
	public labTechDeepMap: LabTechValues;
	/**
	 * For iteration purposes
	 */
	public labTechArray: LabTechKey[];

	public valid: boolean;

	@ViewChild('labTechForm', { static: false }) labTechForm: NgForm;

	constructor(private labtechService: LabtechService) {}

	ngOnInit(): void {
		// If there is no incoming techniquemap we set a default
		if (!this.labTechniques) {
			this.labTechDeepMap = this.labtechService.getFullTechniqueMap();
		} else {
			this.labTechDeepMap = this.labTechniques;
		}

		this.labTechArray = Object.keys(this.labTechDeepMap) as LabTechKey[];

		// Get a map for all selected checkboxes
		this.labTechMap = this.labtechService.getLabTechMap(
			this.labTechDeepMap,
		);
		const frozenDeepMap = Object.freeze({ ...this.labTechDeepMap });
		this.valid = this.labtechService.choicesAreValid(
			this.labTechMap,
			frozenDeepMap,
		);
		if (this.isRequired) {
			this.valid = this.labtechService.hasAtLeastOne(
				this.labTechMap,
				frozenDeepMap,
			);
		}
	}

	ngAfterViewInit(): void {
		this.listenToForm();
	}

	/**
	 * Listens for changes in form and formats output
	 */
	private listenToForm() {
		if (this.labTechForm) {
			this.labTechForm.valueChanges
				.pipe(
					// Only act when form has been changed
					filter((_) => this.labTechForm.dirty),
					distinctUntilChanged(),
					// Emits at most once per 300 ms
					debounceTime(300),
					tap((values) => {
						const map =
							this.labtechService.getMapFromValues(values);
						const frozenDeepMap = Object.freeze({
							...this.labTechDeepMap,
						});
						if (this.isRequired) {
							this.valid = this.labtechService.hasAtLeastOne(
								map,
								frozenDeepMap,
							);
						}
						this.valid = this.labtechService.choicesAreValid(
							map,
							frozenDeepMap,
							this.valid,
						);
					}),
					map((values: LabTechSet) => {
						// The values are of the checkboxes, we merge them with the deepmap
						return this.labtechService.getSelectedValues(
							values,
							this.labTechDeepMap,
						);
					}),
				)
				.subscribe((selectedTechniques) => {
					this.labTechniquesChange.emit(selectedTechniques);
				});
		}
	}

	shouldShowGroupError(subOption: LabTechSubOption): boolean {
		return !this.labtechService.subOptionIsValid(subOption);
	}
}
