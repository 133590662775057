<!--
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
-->
<div
	ngbDropdown
	class="d-flex h-100 me-2"
	placement="bottom-right"
>
	<a
		ngbDropdownToggle
		class="btn btn-link text-base-color m-auto"
		id="menuUserDropdown"
		*ngIf="currentUser"
	>
		<i class="bi-person-fill"></i> {{ currentUser.username }}
		<small>({{ 'role_' + currentUser.currentRole | translate }})</small>
	</a>
	<div
		ngbDropdownMenu
		aria-labelledby="menuUserDropdown"
		class="dropdown-menu bg-white"
	>
		<a
			ngbDropdownItem
			[routerLink]="['/user/profile']"
		>
			{{ 'Update profile' | translate }}
		</a>
		<a
			ngbDropdownItem
			[routerLink]="['/user/change-password']"
		>
			{{ 'Change password' | translate }}
		</a>
		<a
			ngbDropdownItem
			(click)="changeUserRole()"
			*ngIf="currentUser?.roles?.length > 1"
		>
			{{ 'Change userrole' | translate }}
		</a>
		<a
			ngbDropdownItem
			(click)="logout()"
		>
			<i class="bi-box-arrow-right"></i>
			{{ 'Logout' | translate }}
		</a>
	</div>
</div>
