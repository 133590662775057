/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Lab } from '../models/lab';
import { ProfileRepresentation } from '../models/profile-representation';

@Injectable({
	providedIn: 'root',
})
export class LabControllerService extends BaseService {
	constructor(
		config: ApiConfiguration,
		http: HttpClient
	) {
		super(config, http);
	}

  /**
   * Path part for operation getLab
   */
  static readonly GetLabPath = '/api/lab';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLab()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLab$Response(params?: {
  }): Observable<StrictHttpResponse<Lab>> {

    const rb = new RequestBuilder(this.rootUrl, LabControllerService.GetLabPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Lab>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLab$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLab(params?: {
  }): Observable<Lab> {

    return this.getLab$Response(params).pipe(
      map((r: StrictHttpResponse<Lab>) => r.body as Lab)
    );
  }

  /**
   * Path part for operation updateLab
   */
  static readonly UpdateLabPath = '/api/lab';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateLab()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateLab$Response(params: {
    body: Lab
  }): Observable<StrictHttpResponse<Lab>> {

    const rb = new RequestBuilder(this.rootUrl, LabControllerService.UpdateLabPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Lab>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateLab$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateLab(params: {
    body: Lab
  }): Observable<Lab> {

    return this.updateLab$Response(params).pipe(
      map((r: StrictHttpResponse<Lab>) => r.body as Lab)
    );
  }

  /**
   * Path part for operation fixLabEmailAddresses
   */
  static readonly FixLabEmailAddressesPath = '/api/lab/fixLabEmailAddresses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fixLabEmailAddresses()` instead.
   *
   * This method doesn't expect any request body.
   */
  fixLabEmailAddresses$Response(params?: {
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, LabControllerService.FixLabEmailAddressesPath, 'put');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fixLabEmailAddresses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fixLabEmailAddresses(params?: {
  }): Observable<void> {

    return this.fixLabEmailAddresses$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation get2
   */
  static readonly Get2Path = '/api/admin/labs/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `get2()` instead.
   *
   * This method doesn't expect any request body.
   */
  get2$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<Lab>> {

    const rb = new RequestBuilder(this.rootUrl, LabControllerService.Get2Path, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Lab>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `get2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  get2(params: {
    id: number;
  }): Observable<Lab> {

    return this.get2$Response(params).pipe(
      map((r: StrictHttpResponse<Lab>) => r.body as Lab)
    );
  }

  /**
   * Path part for operation update3
   */
  static readonly Update3Path = '/api/admin/labs/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `update3()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update3$Response(params: {
    id: number;
    body: Lab
  }): Observable<StrictHttpResponse<Lab>> {

    const rb = new RequestBuilder(this.rootUrl, LabControllerService.Update3Path, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Lab>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `update3$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update3(params: {
    id: number;
    body: Lab
  }): Observable<Lab> {

    return this.update3$Response(params).pipe(
      map((r: StrictHttpResponse<Lab>) => r.body as Lab)
    );
  }

  /**
   * Path part for operation deactivate1
   */
  static readonly Deactivate1Path = '/api/admin/labs/{id}/deactivate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deactivate1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deactivate1$Response(params: {
    id: number;
    body: Lab
  }): Observable<StrictHttpResponse<Lab>> {

    const rb = new RequestBuilder(this.rootUrl, LabControllerService.Deactivate1Path, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Lab>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deactivate1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deactivate1(params: {
    id: number;
    body: Lab
  }): Observable<Lab> {

    return this.deactivate1$Response(params).pipe(
      map((r: StrictHttpResponse<Lab>) => r.body as Lab)
    );
  }

  /**
   * Path part for operation activate1
   */
  static readonly Activate1Path = '/api/admin/labs/{id}/activate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activate1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activate1$Response(params: {
    id: number;
    body: Lab
  }): Observable<StrictHttpResponse<Lab>> {

    const rb = new RequestBuilder(this.rootUrl, LabControllerService.Activate1Path, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Lab>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activate1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activate1(params: {
    id: number;
    body: Lab
  }): Observable<Lab> {

    return this.activate1$Response(params).pipe(
      map((r: StrictHttpResponse<Lab>) => r.body as Lab)
    );
  }

  /**
   * Path part for operation getAll2
   */
  static readonly GetAll2Path = '/api/admin/labs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAll2()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll2$Response(params?: {
  }): Observable<StrictHttpResponse<Array<Lab>>> {

    const rb = new RequestBuilder(this.rootUrl, LabControllerService.GetAll2Path, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Lab>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAll2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll2(params?: {
  }): Observable<Array<Lab>> {

    return this.getAll2$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Lab>>) => r.body as Array<Lab>)
    );
  }

  /**
   * Path part for operation create1
   */
  static readonly Create1Path = '/api/admin/labs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create1$Response(params: {
    body: Lab
  }): Observable<StrictHttpResponse<Lab>> {

    const rb = new RequestBuilder(this.rootUrl, LabControllerService.Create1Path, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Lab>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create1(params: {
    body: Lab
  }): Observable<Lab> {

    return this.create1$Response(params).pipe(
      map((r: StrictHttpResponse<Lab>) => r.body as Lab)
    );
  }

  /**
   * Path part for operation getLabHubUsers
   */
  static readonly GetLabHubUsersPath = '/api/lab/hubusers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLabHubUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLabHubUsers$Response(params?: {
  }): Observable<StrictHttpResponse<Array<ProfileRepresentation>>> {

    const rb = new RequestBuilder(this.rootUrl, LabControllerService.GetLabHubUsersPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProfileRepresentation>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLabHubUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLabHubUsers(params?: {
  }): Observable<Array<ProfileRepresentation>> {

    return this.getLabHubUsers$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProfileRepresentation>>) => r.body as Array<ProfileRepresentation>)
    );
  }

}
