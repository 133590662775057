/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { LabRequestRepresentation } from '../models/lab-request-representation';

@Injectable({
	providedIn: 'root',
})
export class LabRequestFileControllerService extends BaseService {
	constructor(
		config: ApiConfiguration,
		http: HttpClient
	) {
		super(config, http);
	}

  /**
   * Path part for operation uploadAttachment
   */
  static readonly UploadAttachmentPath = '/api/labrequests/{id}/files';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadAttachment()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadAttachment$Response(params: {
    id: number;
    flowFilename: string;
    flowTotalChunks: number;
    flowChunkNumber: number;
    flowIdentifier: string;
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<LabRequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, LabRequestFileControllerService.UploadAttachmentPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('flowFilename', params.flowFilename, {});
      rb.query('flowTotalChunks', params.flowTotalChunks, {});
      rb.query('flowChunkNumber', params.flowChunkNumber, {});
      rb.query('flowIdentifier', params.flowIdentifier, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabRequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadAttachment$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadAttachment(params: {
    id: number;
    flowFilename: string;
    flowTotalChunks: number;
    flowChunkNumber: number;
    flowIdentifier: string;
    body?: {
'file'?: Blob;
}
  }): Observable<LabRequestRepresentation> {

    return this.uploadAttachment$Response(params).pipe(
      map((r: StrictHttpResponse<LabRequestRepresentation>) => r.body as LabRequestRepresentation)
    );
  }

  /**
   * Path part for operation getAttachment
   */
  static readonly GetAttachmentPath = '/api/labrequests/{id}/files/{attachmentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAttachment()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttachment$Response(params: {
    id: number;
    attachmentId: number;
  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, LabRequestFileControllerService.GetAttachmentPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('attachmentId', params.attachmentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAttachment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttachment(params: {
    id: number;
    attachmentId: number;
  }): Observable<Blob> {

    return this.getAttachment$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation removeAttachment
   */
  static readonly RemoveAttachmentPath = '/api/labrequests/{id}/dataFiles/{attachmentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeAttachment()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeAttachment$Response(params: {
    id: number;
    attachmentId: number;
  }): Observable<StrictHttpResponse<LabRequestRepresentation>> {

    const rb = new RequestBuilder(this.rootUrl, LabRequestFileControllerService.RemoveAttachmentPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('attachmentId', params.attachmentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabRequestRepresentation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeAttachment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeAttachment(params: {
    id: number;
    attachmentId: number;
  }): Observable<LabRequestRepresentation> {

    return this.removeAttachment$Response(params).pipe(
      map((r: StrictHttpResponse<LabRequestRepresentation>) => r.body as LabRequestRepresentation)
    );
  }

}
