/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { SelectionControllerService } from './services/selection-controller.service';
import { RequestControllerService } from './services/request-controller.service';
import { CommentControllerService } from './services/comment-controller.service';
import { ApprovalVoteControllerService } from './services/approval-vote-controller.service';
import { ProfileControllerService } from './services/profile-controller.service';
import { PasswordControllerService } from './services/password-controller.service';
import { LabRequestControllerService } from './services/lab-request-controller.service';
import { PathologyControllerService } from './services/pathology-controller.service';
import { LabControllerService } from './services/lab-controller.service';
import { UserControllerService } from './services/user-controller.service';
import { AgreementFormTemplateControllerService } from './services/agreement-form-template-controller.service';
import { RequestFileControllerService } from './services/request-file-controller.service';
import { LabRequestFileControllerService } from './services/lab-request-file-controller.service';
import { StatusControllerService } from './services/status-controller.service';
import { RequestExportControllerService } from './services/request-export-controller.service';
import { LabProfileControllerService } from './services/lab-profile-controller.service';
import { LabRequestExportControllerService } from './services/lab-request-export-controller.service';
import { RoleControllerService } from './services/role-controller.service';
import { AccessLogControllerService } from './services/access-log-controller.service';
import { CustomErrorControllerService } from './services/custom-error-controller.service';
import { SampleStatusControllerService } from './services/sample-status-controller.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
	imports: [],
	exports: [],
	declarations: [],
	providers: [
	SampleStatusControllerService,
    SelectionControllerService,
    RequestControllerService,
    CommentControllerService,
    ApprovalVoteControllerService,
    ProfileControllerService,
    PasswordControllerService,
    LabRequestControllerService,
    PathologyControllerService,
    LabControllerService,
    UserControllerService,
    AgreementFormTemplateControllerService,
    RequestFileControllerService,
    LabRequestFileControllerService,
    StatusControllerService,
    RequestExportControllerService,
    LabProfileControllerService,
    LabRequestExportControllerService,
    RoleControllerService,
    AccessLogControllerService,
    CustomErrorControllerService,
    ApiConfiguration
	],
})
export class ApiModule {
	static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
		return {
			ngModule: ApiModule,
			providers: [
				{
				provide: ApiConfiguration,
				useValue: params
				}
			]
		}
	}

	constructor(
		@Optional() @SkipSelf() parentModule: ApiModule,
		@Optional() http: HttpClient
	) {
		if (parentModule) {
			throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
		}
		if (!http) {
			throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
			'See also https://github.com/angular/angular/issues/20575');
		}
	}
}
