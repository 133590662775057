/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommentRepresentation } from '../../generated/models';
import { CommentControllerService } from '../../generated/services';

export enum CommentType {
	REQUEST,
	LABREQUEST,
	APPROVAL,
}

@Injectable({
	providedIn: 'root',
})
export class CommentsService {
	constructor(private commentControllerService: CommentControllerService) {}

	/**
	 * Get comments of requested type and id
	 */
	get(
		commentType: CommentType,
		params: { id: string },
	): Observable<Array<CommentRepresentation>> {
		switch (commentType) {
			case CommentType.REQUEST:
				return this.commentControllerService.getComments(params);
			case CommentType.LABREQUEST:
				// No GET for lab request comments
				return null;
			case CommentType.APPROVAL:
				return this.commentControllerService.getApprovalComments(
					params,
				);
			default:
				return null;
		}
	}

	/**
	 * Post comment for requested type and id
	 */
	post(
		commentType: CommentType,
		params: { id: string; body: CommentRepresentation },
	): Observable<CommentRepresentation> {
		switch (commentType) {
			case CommentType.REQUEST:
				return this.commentControllerService.addComment(params);
			case CommentType.LABREQUEST:
				return this.commentControllerService.addLabRequestComment({
					id: +params.id,
					body: params.body,
				});
			case CommentType.APPROVAL:
				return this.commentControllerService.addApprovalComment(params);
			default:
				return null;
		}
	}

	/**
	 * Update comment of requested type and id
	 */
	put(
		commentType: CommentType,
		params: { id: string; commentId: number; body: CommentRepresentation },
	): Observable<CommentRepresentation> {
		switch (commentType) {
			case CommentType.REQUEST:
				return this.commentControllerService.updateComment(params);
			case CommentType.LABREQUEST:
				return this.commentControllerService.updateLabRequestComment({
					id: +params.id,
					commentId: params.commentId,
					body: params.body,
				});
			case CommentType.APPROVAL:
				return this.commentControllerService.updateComment(params);
			default:
				return null;
		}
	}

	/**
	 * Delete comment of requested type and id
	 */
	delete(
		commentType: CommentType,
		params: { id: string; commentId: number },
	): Observable<void> {
		switch (commentType) {
			case CommentType.REQUEST:
				return this.commentControllerService.removeComment(params);
			case CommentType.LABREQUEST:
				return this.commentControllerService.removeLabRequestComment({
					id: +params.id,
					commentId: params.commentId,
				});
			case CommentType.APPROVAL:
				return this.commentControllerService.removeApprovalComment(
					params,
				);
			default:
				return null;
		}
	}
}
