/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';


@Injectable({
	providedIn: 'root',
})
export class LabRequestExportControllerService extends BaseService {
	constructor(
		config: ApiConfiguration,
		http: HttpClient
	) {
		super(config, http);
	}

  /**
   * Path part for operation downloadPaNumbers
   */
  static readonly DownloadPaNumbersPath = '/api/labrequests/{id}/panumbers/csv';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadPaNumbers()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadPaNumbers$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, LabRequestExportControllerService.DownloadPaNumbersPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `downloadPaNumbers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadPaNumbers(params: {
    id: number;
  }): Observable<Blob> {

    return this.downloadPaNumbers$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation downloadAllPaNumbers
   */
  static readonly DownloadAllPaNumbersPath = '/api/labrequests/panumbers/csv';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadAllPaNumbers()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadAllPaNumbers$Response(params: {
    roleName: string;
  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, LabRequestExportControllerService.DownloadAllPaNumbersPath, 'get');
    if (params) {
      rb.query('roleName', params.roleName, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `downloadAllPaNumbers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadAllPaNumbers(params: {
    roleName: string;
  }): Observable<Blob> {

    return this.downloadAllPaNumbers$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

}
