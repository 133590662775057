/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { defaultLanguage, languages, storeLanguage } from '../constants';

@Injectable({
	providedIn: 'root',
})
export class LanguageService {
	constructor(
		private translateService: TranslateService,
		private router: Router,
	) {}

	/**
	 * Returns the current language code or default
	 * @returns The language code from localStorage
	 */
	getLanguage(): string {
		let useLanguage: string = '';

		if (localStorage.getItem(storeLanguage) !== null) {
			useLanguage = localStorage.getItem(storeLanguage) as string;
		} else {
			useLanguage = defaultLanguage;
		}

		// return default language if language is not found in language list
		if (languages.find((lang) => lang.code === useLanguage) === undefined) {
			useLanguage = defaultLanguage;
		}

		return useLanguage;
	}

	/**
	 * Store the current language code
	 */
	setLanguage(language: string) {
		// keep in local storage
		localStorage.setItem(storeLanguage, language);

		// set the language to use
		this.translateService.use(language);

		// 'reload' the page
		this.router.navigate([this.router.url]);
	}
}
