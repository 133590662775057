/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
// Which keys to use for storing info in browser
export const storeLanguage = 'palga-language';
export const storePageSize = 'palga-pageSize';
export const storeUser = 'palga-user';
export const storeJwt = 'palga-jwt';
export const storeLastRole = 'palga-lastRole';

// Languages to select
export const languages: any[] = [
	{
		code: 'nl',
		translationTag: 'Dutch', // translation tag
	},
	{
		code: 'en',
		translationTag: 'English', // translation tag
	},
];
export const defaultLanguage: string = 'nl';

// Page size options used in datasources/tables
export const pageSizeOptions: number[] = [1, 2, 5, 10, 25, 50, 100];
export const defaultPageSize: number = 10;

export const specialismList = [
	{ label: '(Please select a specialism)', value: '' },
	{ label: 'Cardiology', value: 'Cardiology' },
	{ label: 'Dermatology', value: 'Dermatology' },
	{ label: 'Epidemiology', value: 'Epidemiology' },
	{ label: 'Endocrinology', value: 'Endocrinology' },
	{ label: 'Gastroenterology', value: 'Gastroenterology' },
	{ label: 'Gynaecology', value: 'Gynaecology' },
	{ label: 'Haematology', value: 'Haematology' },
	{ label: 'Internal Medicine', value: 'Internal Medicine' },
	{ label: 'Lung Disease', value: 'Lung Disease' },
	{ label: 'Medical Oncology', value: 'Medical Oncology' },
	{ label: 'Neurology', value: 'Neurology' },
	{ label: 'Pathology', value: 'Pathology' },
	{ label: 'Primary care', value: 'Primary care' },
	{ label: 'Radiology', value: 'Radiology' },
	{ label: 'Radiotherapy', value: 'Radiotherapy' },
	{ label: 'Surgery', value: 'Surgery' },
	{ label: 'Throat-nose-ear', value: 'Throat-nose-ear' },
	{ label: 'Urology', value: 'Urology' },
	{ label: '(Other)', value: 'other' },
];

export const searchFilterApplyTimeout = 500;

export enum Role {
	palga = 'palga',
	requester = 'requester',
	scientific_council = 'scientific_council',
	lab_user = 'lab_user',
	hub_user = 'hub_user',
}

// christmas easter egg, disable for now
const now = new Date();
// export const isChristmas: boolean = now.getMonth() === 11 && now.getDate() > 11;
export const isChristmas: boolean = false;
