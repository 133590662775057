/*
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
*/
import { Component, Input, OnInit } from '@angular/core';
import { LabTechValues } from 'src/app/modules/shared/interfaces/lab-tech.type';

export interface LabTechDisplay {
	paBlock: LabTechValues;
}

@Component({
	selector: 'palga-lab-tech-display',
	templateUrl: './lab-tech-display.component.html',
	styleUrls: ['./lab-tech-display.component.scss'],
})
export class LabTechDisplayComponent implements OnInit {
	@Input() labTech: LabTechDisplay;

	labTechKeys: string[];

	constructor() {}

	ngOnInit(): void {
		if (this.labTech?.paBlock) {
			this.labTechKeys = Object.keys(this.labTech.paBlock);
		}
	}
}
