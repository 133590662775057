<!--
Copyright (C) 2016-2024 Stichting Palga
This file is distributed under the GNU Affero General Public License
(see accompanying file LICENSE).
-->
<!-- just a logo -->
<div
	class="palga-logo"
	*ngIf="!showDntpTheme"
>
	<a
		class="h-100 w-100 d-block"
		[routerLink]="['/']"
	>
		<img
			src="/assets/images/palga.png"
			class="img-fluid mx-auto d-block h-100 p-2"
		/>
	</a>
</div>

<div
	class="dntp-logo no-bg"
	*ngIf="showDntpTheme"
>
	<a
		class="h-100 w-100 d-block"
		[routerLink]="['/']"
	>
		<img
			*ngIf="isChristmas"
			src="/assets/images/santa.png"
			alt="Santa baby"
			class="santa-hat"
		/>
		<img
			src="/assets/images/logo_dntp.png"
			class="img-fluid mx-auto d-block h-100 mx-auto"
		/>
	</a>
</div>
